var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "trim-whitespace", rawName: "v-trim-whitespace" }],
      staticClass: "turmoil"
    },
    [
      _c(
        "div",
        { staticClass: "events-board" },
        [
          _vm.turmoil.distant
            ? _c("global-event", {
                attrs: { globalEvent: _vm.turmoil.distant, type: "distant" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.turmoil.coming
            ? _c("global-event", {
                attrs: { globalEvent: _vm.turmoil.coming, type: "coming" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.turmoil.current
            ? _c("global-event", {
                attrs: { globalEvent: _vm.turmoil.current, type: "current" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "turmoil-board" }, [
        _c("div", { staticClass: "turmoil-header" }, [
          _c(
            "div",
            { staticClass: "turmoil-lobby" },
            _vm._l(5, function(n) {
              return _c("div", { key: n, staticClass: "lobby-spot" }, [
                _vm.turmoil.lobby.length >= n
                  ? _c("div", {
                      class: "player-token " + _vm.turmoil.lobby[n - 1]
                    })
                  : _vm._e()
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dominant-party-name" }, [
            _c(
              "div",
              {
                directives: [{ name: "i18n", rawName: "v-i18n" }],
                class:
                  "party-name party-name--" +
                  _vm.partyNameToCss(_vm.turmoil.ruling)
              },
              [_vm._v(_vm._s(_vm.turmoil.ruling))]
            )
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "dominant-party-bonus",
            domProps: { innerHTML: _vm._s(_vm.getPolicy(_vm.turmoil.ruling)) }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "policy-user-cubes" },
            [
              _vm._l(_vm.turmoil.policyActionUsers, function(n) {
                return [
                  n.turmoilPolicyActionUsed
                    ? _c("div", {
                        key: n.color,
                        class: "policy-use-marker board-cube--" + n.color
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  n.politicalAgendasActionUsedCount > 0
                    ? _c(
                        "div",
                        {
                          key: n.color,
                          class: "policy-use-marker board-cube--" + n.color
                        },
                        [_vm._v(_vm._s(n.politicalAgendasActionUsedCount))]
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "chairman-spot" }, [
            _vm.turmoil.chairman
              ? _c("div", { class: "player-token " + _vm.turmoil.chairman })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "turmoil-reserve" },
            _vm._l(_vm.turmoil.reserve.length, function(n) {
              return _c("div", { key: n, staticClass: "lobby-spot" }, [
                _vm.turmoil.reserve.length >= n
                  ? _c(
                      "div",
                      {
                        class:
                          "player-token " + _vm.turmoil.reserve[n - 1].color
                      },
                      [_vm._v(_vm._s(_vm.turmoil.reserve[n - 1].number))]
                    )
                  : _vm._e()
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "policies" }, [
            _c("div", { staticClass: "policies-title" }, [
              _c(
                "a",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "policies-clickable",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleMe()
                    }
                  }
                },
                [_vm._v("Policies")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isVisible(),
                    expression: "isVisible()"
                  }
                ],
                staticClass: "policies-global"
              },
              _vm._l(_vm.turmoil.parties, function(party) {
                return _c(
                  "div",
                  { key: party.name, staticClass: "policy-block" },
                  [
                    _c(
                      "div",
                      {
                        directives: [{ name: "i18n", rawName: "v-i18n" }],
                        class:
                          "party-name party-name--" +
                          _vm.partyNameToCss(party.name)
                      },
                      [_vm._v(_vm._s(party.name))]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "policy-bonus",
                      domProps: { innerHTML: _vm._s(_vm.getPolicy(party.name)) }
                    })
                  ]
                )
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-leaders" },
          _vm._l(_vm.turmoil.parties, function(party) {
            return _c(
              "div",
              {
                key: party.name,
                class: [
                  "leader-spot",
                  "leader-spot--" + _vm.partyNameToCss(party.name),
                  {
                    "player-token-new-leader":
                      party.name === _vm.turmoil.dominant
                  }
                ]
              },
              [
                _c("div", { staticClass: "delegate-spot" }, [
                  party.partyLeader
                    ? _c("div", { class: ["player-token", party.partyLeader] })
                    : _vm._e()
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-parties" },
          _vm._l(_vm.turmoil.parties, function(party) {
            return _c(
              "div",
              {
                key: party.name,
                class:
                  "board-party board-party--" + _vm.partyNameToCss(party.name)
              },
              [
                _c(
                  "div",
                  { staticClass: "grid-delegates" },
                  _vm._l(6, function(n) {
                    return _c("div", { key: n, staticClass: "delegate-spot" }, [
                      party.delegates.length >= n
                        ? _c(
                            "div",
                            {
                              class:
                                "player-token " + party.delegates[n - 1].color
                            },
                            [_vm._v(_vm._s(party.delegates[n - 1].number))]
                          )
                        : _vm._e()
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [{ name: "i18n", rawName: "v-i18n" }],
                    class:
                      "party-name party-name--" + _vm.partyNameToCss(party.name)
                  },
                  [_vm._v(_vm._s(party.name))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "party-bonus" }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.getBonus(party.name)) }
                  })
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._m(0)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "turmoil-party-transition-arrow-grid" }, [
      _c("div", { staticClass: "turmoil-party-transition-arrow" }),
      _vm._v(" "),
      _c("div", { staticClass: "turmoil-party-transition-arrow" }),
      _vm._v(" "),
      _c("div", { staticClass: "turmoil-party-transition-arrow" }),
      _vm._v(" "),
      _c("div", { staticClass: "turmoil-party-transition-arrow" }),
      _vm._v(" "),
      _c("div", { staticClass: "turmoil-party-transition-arrow" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }