export enum TurmoilPolicy {
  MARS_FIRST_DEFAULT_POLICY = 'mfp01',
  MARS_FIRST_POLICY_2 = 'mfp02',
  MARS_FIRST_POLICY_3 = 'mfp03',
  MARS_FIRST_POLICY_4 = 'mfp04',
  SCIENTISTS_DEFAULT_POLICY = 'sp01',
  SCIENTISTS_POLICY_2 = 'sp02',
  SCIENTISTS_POLICY_3 = 'sp03',
  SCIENTISTS_POLICY_4 = 'sp04',
  UNITY_DEFAULT_POLICY = 'up01',
  UNITY_POLICY_2 = 'up02',
  UNITY_POLICY_3 = 'up03',
  UNITY_POLICY_4 = 'up04',
  GREENS_DEFAULT_POLICY = 'gp01',
  GREENS_POLICY_2 = 'gp02',
  GREENS_POLICY_3 = 'gp03',
  GREENS_POLICY_4 = 'gp04',
  REDS_DEFAULT_POLICY = 'rp01',
  REDS_POLICY_2 = 'rp02',
  REDS_POLICY_3 = 'rp03',
  REDS_POLICY_4 = 'rp04',
  KELVINISTS_DEFAULT_POLICY = 'kp01',
  KELVINISTS_POLICY_2 = 'kp02',
  KELVINISTS_POLICY_3 = 'kp03',
  KELVINISTS_POLICY_4 = 'kp04',
}
