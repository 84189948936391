export enum CardName {
    // Standard projects:
    SELL_PATENTS_STANDARD_PROJECT = 'Sell Patents',
    POWER_PLANT_STANDARD_PROJECT = 'Power Plant:SP',
    ASTEROID_STANDARD_PROJECT = 'Asteroid:SP',
    BUFFER_GAS_STANDARD_PROJECT = 'Buffer Gas',
    BUILD_COLONY_STANDARD_PROJECT = 'Colony',
    AQUIFER_STANDARD_PROJECT = 'Aquifer',
    GREENERY_STANDARD_PROJECT = 'Greenery',
    CITY_STANDARD_PROJECT = 'City',
    AIR_SCRAPPING_STANDARD_PROJECT = 'Air Scrapping',

    // Standard actions:
    CONVERT_PLANTS = 'Convert Plants',
    CONVERT_HEAT = 'Convert Heat',

    ACQUIRED_COMPANY = 'Acquired Company',
    ADAPTATION_TECHNOLOGY = 'Adaptation Technology',
    ADAPTED_LICHEN = 'Adapted Lichen',
    ADVANCED_ALLOYS = 'Advanced Alloys',
    ADVANCED_ECOSYSTEMS = 'Advanced Ecosystems',
    AEROBRAKED_AMMONIA_ASTEROID = 'Aerobraked Ammonia Asteroid',
    AI_CENTRAL = 'AI Central',
    AIR_RAID = 'Air Raid',
    AIRLINERS = 'Airliners',
    ALGAE = 'Algae',
    ANTI_GRAVITY_TECHNOLOGY = 'Anti-Gravity Technology',
    ANTS = 'Ants',
    AQUIFER_PUMPING = 'Aquifer Pumping',
    AQUIFER_TURBINES = 'Aquifer Turbines',
    ARCHAEBACTERIA = 'ArchaeBacteria',
    ARTIFICIAL_LAKE = 'Artificial Lake',
    ARTIFICIAL_PHOTOSYNTHESIS = 'Artificial Photosynthesis',
    ARCTIC_ALGAE = 'Arctic Algae',
    ASTEROID = 'Asteroid',
    ASTEROID_MINING = 'Asteroid Mining',
    ASTEROID_MINING_CONSORTIUM = 'Asteroid Mining Consortium',
    ATMO_COLLECTORS = 'Atmo Collectors',
    BREATHING_FILTERS = 'Breathing Filters',
    BRIBED_COMMITTEE = 'Bribed Committee',
    BEAM_FROM_A_THORIUM_ASTEROID = 'Beam From A Thorium Asteroid',
    BIG_ASTEROID = 'Big Asteroid',
    BIOMASS_COMBUSTORS = 'Biomass Combustors',
    BIRDS = 'Birds',
    BLACK_POLAR_DUST = 'Black Polar Dust',
    BUILDING_INDUSTRIES = 'Building Industries',
    BUSHES = 'Bushes',
    BUSINESS_CONTACTS = 'Business Contacts',
    BUSINESS_NETWORK = 'Business Network',
    CALLISTO_PENAL_MINES = 'Callisto Penal Mines',
    CARBONATE_PROCESSING = 'Carbonate Processing',
    CAPITAL = 'Capital',
    CARETAKER_CONTRACT = 'Caretaker Contract',
    CARTEL = 'Cartel',
    CEOS_FAVORITE_PROJECT = 'CEO\'s Favorite Project',
    CLOUD_SEEDING = 'Cloud Seeding',
    COLONIZER_TRAINING_CAMP = 'Colonizer Training Camp',
    COMET = 'Comet',
    COMMERCIAL_DISTRICT = 'Commercial District',
    COMMUNITY_SERVICES = 'Community Services',
    CONSCRIPTION = 'Conscription',
    CONVOY_FROM_EUROPA = 'Convoy From Europa',
    CORONA_EXTRACTOR = 'Corona Extractor',
    CORPORATE_STRONGHOLD = 'Corporate Stronghold',
    CRYO_SLEEP = 'Cryo Sleep',
    CUPOLA_CITY = 'Cupola City',
    DECOMPOSERS = 'Decomposers',
    DEEP_WELL_HEATING = 'Deep Well Heating',
    DEIMOS_DOWN = 'Deimos Down',
    DESIGNED_MICRO_ORGANISMS = 'Designed Micro-organisms',
    DEVELOPMENT_CENTER = 'Development Center',
    DIRIGIBLES = 'Dirigibles',
    DOME_FARMING = 'Dome Farming',
    DOMED_CRATER = 'Domed Crater',
    DUST_SEALS = 'Dust Seals',
    EARLY_SETTLEMENT = 'Early Settlement',
    EARTH_CATAPULT = 'Earth Catapult',
    EARTH_ELEVATOR = 'Earth Elevator',
    EARTH_OFFICE = 'Earth Office',
    ECCENTRIC_SPONSOR = 'Eccentric Sponsor',
    ECOLOGICAL_ZONE = 'Ecological Zone',
    ECOLOGY_EXPERTS = 'Ecology Experts',
    ECOLOGY_RESEARCH = 'Ecology Research',
    ELECTRO_CATAPULT = 'Electro Catapult',
    ENERGY_SAVING = 'Energy Saving',
    ENERGY_TAPPING = 'Energy Tapping',
    EOS_CHASMA_NATIONAL_PARK = 'Eos Chasma National Park',
    EQUATORIAL_MAGNETIZER = 'Equatorial Magnetizer',
    EXTREME_COLD_FUNGUS = 'Extreme-Cold Fungus',
    FARMING = 'Farming',
    FISH = 'Fish',
    FLOATER_LEASING = 'Floater Leasing',
    FLOATER_PROTOTYPES = 'Floater Prototypes',
    FLOATER_TECHNOLOGY = 'Floater Technology',
    FLOODING = 'Flooding',
    FOOD_FACTORY = 'Food Factory',
    FUEL_FACTORY = 'Fuel Factory',
    FUELED_GENERATORS = 'Fueled Generators',
    FUSION_POWER = 'Fusion Power',
    GALILEAN_WAYSTATION = 'Galilean Waystation',
    GANYMEDE_COLONY = 'Ganymede Colony',
    GENE_REPAIR = 'Gene Repair',
    GEOTHERMAL_POWER = 'Geothermal Power',
    GHG_PRODUCING_BACTERIA = 'GHG Producing Bacteria',
    GHG_FACTORIES = 'GHG Factories',
    GIANT_ICE_ASTEROID = 'Giant Ice Asteroid',
    GIANT_SPACE_MIRROR = 'Giant Space Mirror',
    GRASS = 'Grass',
    GREAT_AQUIFER = 'Great Aquifer',
    GREAT_DAM = 'Great Dam',
    GREAT_ESCARPMENT_CONSORTIUM = 'Great Escarpment Consortium',
    GREENHOUSES = 'Greenhouses',
    GYROPOLIS = 'Gyropolis',
    HACKERS = 'Hackers',
    HEATHER = 'Heather',
    HEAT_TRAPPERS = 'Heat Trappers',
    HEAVY_TAXATION = 'Heavy Taxation',
    HERBIVORES = 'Herbivores',
    HIRED_RAIDERS = 'Hired Raiders',
    HOUSE_PRINTING = 'House Printing',
    ICE_ASTEROID = 'Ice Asteroid',
    ICE_CAP_MELTING = 'Ice Cap Melting',
    ICE_MOON_COLONY = 'Ice Moon Colony',
    IMMIGRANT_CITY = 'Immigrant City',
    IMMIGRATION_SHUTTLES = 'Immigration Shuttles',
    IMPACTOR_SWARM = 'Impactor Swarm',
    IMPORTED_GHG = 'Imported GHG',
    IMPORTED_HYDROGEN = 'Imported Hydrogen',
    IMPORTED_NITROGEN = 'Imported Nitrogen',
    IMPORT_OF_ADVANCED_GHG = 'Import of Advanced GHG',
    INDENTURED_WORKERS = 'Indentured Workers',
    INDUSTRIAL_MICROBES = 'Industrial Microbes',
    INSECTS = 'Insects',
    INSULATION = 'Insulation',
    INTERPLANETARY_COLONY_SHIP = 'Interplanetary Colony Ship',
    INTERSTELLAR_COLONY_SHIP = 'Interstellar Colony Ship',
    INVENTION_CONTEST = 'Invention Contest',
    INVENTORS_GUILD = 'Inventors Guild',
    INVESTMENT_LOAN = 'Investment Loan',
    IO_MINING_INDUSTRIES = 'Io Mining Industries',
    IRONWORKS = 'Ironworks',
    JOVIAN_LANTERNS = 'Jovian Lanterns',
    JUPITER_FLOATING_STATION = 'Jupiter Floating Station',
    KELP_FARMING = 'Kelp Farming',
    LAGRANGE_OBSERVATORY = 'Lagrange Observatory',
    LAKE_MARINERIS = 'Lake Marineris',
    LAND_CLAIM = 'Land Claim',
    LARGE_CONVOY = 'Large Convoy',
    LAVA_FLOWS = 'Lava Flows',
    LAVA_TUBE_SETTLEMENT = 'Lava Tube Settlement',
    LICHEN = 'Lichen',
    LIGHTNING_HARVEST = 'Lightning Harvest',
    LIVESTOCK = 'Livestock',
    LOCAL_HEAT_TRAPPING = 'Local Heat Trapping',
    LUNAR_BEAM = 'Lunar Beam',
    LUNA_GOVERNOR = 'Luna Governor',
    LUNAR_EXPORTS = 'Lunar Exports',
    LUNAR_MINING = 'Lunar Mining',
    MAGNETIC_FIELD_DOME = 'Magnetic Field Dome',
    MAGNETIC_FIELD_GENERATORS = 'Magnetic Field Generators',
    MARKET_MANIPULATION = 'Market Manipulation',
    MARTIAN_INDUSTRIES = 'Martian Industries',
    MARTIAN_ZOO = 'Martian Zoo',
    MANGROVE = 'Mangrove',
    MARS_UNIVERSITY = 'Mars University',
    MARTIAN_RAILS = 'Martian Rails',
    MASS_CONVERTER = 'Mass Converter',
    MEDIA_ARCHIVES = 'Media Archives',
    MEDIA_GROUP = 'Media Group',
    MEDICAL_LAB = 'Medical Lab',
    METHANE_FROM_TITAN = 'Methane From Titan',
    MICRO_MILLS = 'Micro-Mills',
    MINE = 'Mine',
    MINERAL_DEPOSIT = 'Mineral Deposit',
    MIRANDA_RESORT = 'Miranda Resort',
    MINING_AREA = 'Mining Area',
    MINING_COLONY = 'Mining Colony',
    MINING_EXPEDITION = 'Mining Expedition',
    MINING_OPERATIONS = 'Mining Operations',
    MINING_QUOTA = 'Mining Quota',
    MINING_RIGHTS = 'Mining Rights',
    MINORITY_REFUGE = 'Minority Refuge',
    MOHOLE = 'Mohole',
    MOHOLE_AREA = 'Mohole Area',
    MOHOLE_EXCAVATION = 'Mohole Excavation',
    MOLECULAR_PRINTING = 'Molecular Printing',
    MOSS = 'Moss',
    NATURAL_PRESERVE = 'Natural Preserve',
    NITRITE_REDUCING_BACTERIA = 'Nitrite Reducing Bacteria',
    NITROGEN_RICH_ASTEROID = 'Nitrogen-Rich Asteroid',
    NITROGEN_FROM_TITAN = 'Nitrogen from Titan',
    NITROPHILIC_MOSS = 'Nitrophilic Moss',
    NOCTIS_CITY = 'Noctis City',
    NOCTIS_FARMING = 'Noctis Farming',
    NUCLEAR_POWER = 'Nuclear Power',
    NUCLEAR_ZONE = 'Nuclear Zone',
    OLYMPUS_CONFERENCE = 'Olympus Conference',
    OMNICOURT = 'Omnicourt',
    OPEN_CITY = 'Open City',
    OPTIMAL_AEROBRAKING = 'Optimal Aerobraking',
    ORE_PROCESSOR = 'Ore Processor',
    PERMAFROST_EXTRACTION = 'Permafrost Extraction',
    PEROXIDE_POWER = 'Peroxide Power',
    PETS = 'Pets',
    PHOBOS_SPACE_HAVEN = 'Phobos Space Haven',
    PHYSICS_COMPLEX = 'Physics Complex',
    PIONEER_SETTLEMENT = 'Pioneer Settlement',
    PLANTATION = 'Plantation',
    POLAR_INDUSTRIES = 'Polar Industries',
    POWER_GRID = 'Power Grid',
    POWER_INFRASTRUCTURE = 'Power Infrastructure',
    POWER_PLANT = 'Power Plant',
    POWER_SUPPLY_CONSORTIUM = 'Power Supply Consortium',
    PREDATORS = 'Predators',
    PRODUCTIVE_OUTPOST = 'Productive Outpost',
    PROTECTED_HABITATS = 'Protected Habitats',
    PROTECTED_VALLEY = 'Protected Valley',
    PSYCHROPHILES = 'Psychrophiles',
    QUANTUM_COMMUNICATIONS = 'Quantum Communications',
    QUANTUM_EXTRACTOR = 'Quantum Extractor',
    RAD_CHEM_FACTORY = 'Rad-Chem Factory',
    RAD_SUITS = 'Rad-Suits',
    RED_SPOT_OBSERVATORY = 'Red Spot Observatory',
    REFUGEE_CAMP = 'Refugee Camp',
    REGOLITH_EATERS = 'Regolith Eaters',
    RELEASE_OF_INERT_GASES = 'Release of Inert Gases',
    RESEARCH = 'Research',
    RESEARCH_OUTPOST = 'Research Outpost',
    RESEARCH_COLONY = 'Research Colony',
    RESTRICTED_AREA = 'Restricted Area',
    ROBOTIC_WORKFORCE = 'Robotic Workforce',
    ROVER_CONSTRUCTION = 'Rover Construction',
    RIM_FREIGHTERS = 'Rim Freighters',
    SABOTAGE = 'Sabotage',
    SATELLITES = 'Satellites',
    SEARCH_FOR_LIFE = 'Search For Life',
    SECURITY_FLEET = 'Security Fleet',
    SELF_SUFFICIENT_SETTLEMENT = 'Self-Sufficient Settlement',
    SISTER_PLANET_SUPPORT = 'Sister Planet Support',
    SKY_DOCKS = 'Sky Docks',
    SMALL_ANIMALS = 'Small Animals',
    SOIL_FACTORY = 'Soil Factory',
    SOLAR_POWER = 'Solar Power',
    SOLAR_PROBE = 'Solar Probe',
    SOLAR_REFLECTORS = 'Solar Reflectors',
    SOLARNET = 'Solarnet',
    SPACE_ELEVATOR = 'Space Elevator',
    SPACE_PORT = 'Space Port',
    SPACE_PORT_COLONY = 'Space Port Colony',
    SPINOFF_DEPARTMENT = 'Spin-off Department',
    STRIP_MINE = 'Strip Mine',
    SUBTERRANEAN_RESERVOIR = 'Subterranean Reservoir',
    SUBZERO_SALT_FISH = 'Sub-zero Salt Fish',
    SHUTTLES = 'Shuttles',
    SOLAR_WIND_POWER = 'Solar Wind Power',
    SOLETTA = 'Soletta',
    SPACE_MIRRORS = 'Space Mirrors',
    SPACE_STATION = 'Space Station',
    SPECIAL_DESIGN = 'Special Design',
    SPONSORS = 'Sponsors',
    STEELWORKS = 'Steelworks',
    STANDARD_TECHNOLOGY = 'Standard Technology',
    SYMBIOTIC_FUNGUS = 'Symbiotic Fungus',
    TARDIGRADES = 'Tardigrades',
    TECHNOLOGY_DEMONSTRATION = 'Technology Demonstration',
    TECTONIC_STRESS_POWER = 'Tectonic Stress Power',
    TERRAFORMING_GANYMEDE = 'Terraforming Ganymede',
    TITAN_AIRSCRAPPING = 'Titan Air-scrapping',
    TITAN_FLOATING_LAUNCHPAD = 'Titan Floating Launch-pad',
    TITAN_SHUTTLES = 'Titan Shuttles',
    TITANIUM_MINE = 'Titanium Mine',
    TOLL_STATION = 'Toll Station',
    TOWING_A_COMET = 'Towing A Comet',
    TRADE_ENVOYS = 'Trade Envoys',
    TRADING_COLONY = 'Trading Colony',
    TRANS_NEPTUNE_PROBE = 'Trans-Neptune Probe',
    TREES = 'Trees',
    TROPICAL_RESORT = 'Tropical Resort',
    TUNDRA_FARMING = 'Tundra Farming',
    UNDERGROUND_CITY = 'Underground City',
    UNDERGROUND_DETONATIONS = 'Underground Detonations',
    URBAN_DECOMPOSERS = 'Urban Decomposers',
    URBANIZED_AREA = 'Urbanized Area',
    VESTA_SHIPYARD = 'Vesta Shipyard',
    VIRAL_ENHANCERS = 'Viral Enhancers',
    VIRUS = 'Virus',
    WARP_DRIVE = 'Warp Drive',
    WATER_IMPORT_FROM_EUROPA = 'Water Import From Europa',
    // Venus:
    AERIAL_MAPPERS = 'Aerial Mappers',
    AEROSPORT_TOURNAMENT = 'Aerosport Tournament',
    AIR_SCRAPPING_EXPEDITION = 'Air-Scrapping Expedition',
    APHRODITE = 'Aphrodite',
    ATALANTA_PLANITIA_LAB = 'Atalanta Planitia Lab',
    ATMOSCOOP = 'Atmoscoop',
    CELESTIC = 'Celestic',
    COMET_FOR_VENUS = 'Comet for Venus',
    CORRODER_SUITS = 'Corroder Suits',
    DAWN_CITY = 'Dawn City',
    DEUTERIUM_EXPORT = 'Deuterium Export',
    EXTRACTOR_BALLOONS = 'Extractor Balloons',
    EXTREMOPHILES = 'Extremophiles',
    FLOATING_HABS = 'Floating Habs',
    FORCED_PRECIPITATION = 'Forced Precipitation',
    FREYJA_BIODOMES = 'Freyja Biodomes',
    GHG_IMPORT_FROM_VENUS = 'GHG Import From Venus',
    GIANT_SOLAR_SHADE = 'Giant Solar Shade',
    HYDROGEN_TO_VENUS = 'Hydrogen to Venus',
    IO_SULPHUR_RESEARCH = 'Io Sulphur Research',
    ISHTAR_MINING = 'Ishtar Mining',
    JET_STREAM_MICROSCRAPPERS = 'Jet Stream Microscrappers',
    LOCAL_SHADING = 'Local Shading',
    LUNA_METROPOLIS = 'Luna Metropolis',
    LUXURY_FOODS = 'Luxury Foods',
    MAXWELL_BASE = 'Maxwell Base',
    MORNING_STAR_INC = 'Morning Star Inc.',
    NEUTRALIZER_FACTORY = 'Neutralizer Factory',
    ORBITAL_REFLECTORS = 'Orbital Reflectors',
    ROTATOR_IMPACTS = 'Rotator Impacts',
    SPIN_INDUCING_ASTEROID = 'Spin-Inducing Asteroid',
    SPONSORED_ACADEMIES = 'Sponsored Academies',
    STRATOPOLIS = 'Stratopolis',
    STRATOSPHERIC_BIRDS = 'Stratospheric Birds',
    SULPHUR_EATING_BACTERIA = 'Sulphur-Eating Bacteria',
    SULPHUR_EXPORTS = 'Sulphur Exports',
    TERRAFORMING_CONTRACT = 'Terraforming Contract',
    THERMOPHILES = 'Thermophiles',
    VENUS_GOVERNOR = 'Venus Governor',
    VENUSIAN_ANIMALS = 'Venusian Animals',
    VENUSIAN_INSECTS = 'Venusian Insects',
    VENUSIAN_PLANTS = 'Venusian Plants',
    VENUS_MAGNETIZER = 'Venus Magnetizer',
    VENUS_SOILS = 'Venus Soils',
    VENUS_WAYSTATION = 'Venus Waystation',
    VIRON = 'Viron',
    WATER_TO_VENUS = 'Water to Venus',
    WATER_SPLITTING_PLANT = 'Water Splitting Plant',
    WAVE_POWER = 'Wave Power',
    WINDMILLS = 'Windmills',
    WORMS = 'Worms',
    ZEPPELINS = 'Zeppelins',

    // Corps:
    BEGINNER_CORPORATION = 'Beginner Corporation',
    CREDICOR = 'CrediCor',
    ECOLINE = 'EcoLine',
    HELION = 'Helion',
    INTERPLANETARY_CINEMATICS = 'Interplanetary Cinematics',
    INVENTRIX = 'Inventrix',
    MINING_GUILD = 'Mining Guild',
    PHOBOLOG = 'PhoboLog',
    SATURN_SYSTEMS = 'Saturn Systems',
    TERACTOR = 'Teractor',
    THARSIS_REPUBLIC = 'Tharsis Republic',
    THORGATE = 'Thorgate',
    UNITED_NATIONS_MARS_INITIATIVE = 'United Nations Mars Initiative',
    ACQUIRED_SPACE_AGENCY = 'Acquired Space Agency',
    // Preludes:
    ALLIED_BANKS = 'Allied Banks',
    BIOFUELS = 'Biofuels',
    BIOLAB = 'Biolab',
    BIOSPHERE_SUPPORT = 'Biosphere Support',
    BUSINESS_EMPIRE = 'Business Empire',
    CHEUNG_SHING_MARS = 'Cheung Shing MARS',
    DONATION = 'Donation',
    EXPERIMENTAL_FOREST = 'Experimental Forest',
    GALILEAN_MINING = 'Galilean Mining',
    HUGE_ASTEROID = 'Huge Asteroid',
    IO_RESEARCH_OUTPOST = 'Io Research Outpost',
    LOAN = 'Loan',
    MARTIAN_SURVEY = 'Martian Survey',
    METAL_RICH_ASTEROID = 'Metal-Rich Asteroid',
    METALS_COMPANY = 'Metals Company',
    NITROGEN_SHIPMENT = 'Nitrogen Shipment',
    ORBITAL_CONSTRUCTION_YARD = 'Orbital Construction Yard',
    POINT_LUNA = 'Point Luna',
    POWER_GENERATION = 'Power Generation',
    RESEARCH_COORDINATION = 'Research Coordination',
    RESEARCH_NETWORK = 'Research Network',
    ROBINSON_INDUSTRIES = 'Robinson Industries',
    SF_MEMORIAL = 'SF Memorial',
    SMELTING_PLANT = 'Smelting Plant',
    SOCIETY_SUPPORT = 'Society Support',
    SPACE_HOTELS = 'Space Hotels',
    SUPPLIER = 'Supplier',
    SUPPLY_DROP = 'Supply Drop',
    UNMI_CONTRACTOR = 'UNMI Contractor',
    VALLEY_TRUST = 'Valley Trust',
    VITOR = 'Vitor',
    ARIDOR = 'Aridor',
    ARKLIGHT = 'Arklight',
    POSEIDON = 'Poseidon',
    STORMCRAFT_INCORPORATED = 'Stormcraft Incorporated',
    ARCADIAN_COMMUNITIES = 'Arcadian Communities',
    ASTRODRILL = 'Astrodrill',
    ADVERTISING = 'Advertising',
    PHARMACY_UNION = 'Pharmacy Union',
    INDUSTRIAL_CENTER = 'Industrial Center',
    FACTORUM = 'Factorum',
    LAKEFRONT_RESORTS = 'Lakefront Resorts',
    TERRALABS_RESEARCH = 'Terralabs Research',
    SEPTUM_TRIBUS = 'Septem Tribus',
    MONS_INSURANCE = 'Mons Insurance',
    SPLICE = 'Splice',
    PHILARES = 'Philares',
    PRISTAR = 'Pristar',
    RECYCLON = 'Recyclon',
    UTOPIA_INVEST = 'Utopia Invest',
    MANUTECH = 'Manutech',
    SELF_REPLICATING_ROBOTS = 'Self-replicating Robots',
    POLYPHEMOS = 'Polyphemos',
    PENGUINS = 'Penguins',
    SMALL_ASTEROID = 'Small Asteroid',
    SNOW_ALGAE = 'Snow Algae',
    AERIAL_LENSES = 'Aerial Lenses',
    BANNED_DELEGATE = 'Banned Delegate',
    CULTURAL_METROPOLIS = 'Cultural Metropolis',
    DIASPORA_MOVEMENT = 'Diaspora Movement',
    EVENT_ANALYSTS = 'Event Analysts',
    GMO_CONTRACT = 'GMO Contract',
    MARTIAN_MEDIA_CENTER = 'Martian Media Center',
    PARLIAMENT_HALL = 'Parliament Hall',
    PR_OFFICE = 'PR Office',
    PUBLIC_CELEBRATIONS = 'Public Celebrations',
    RECRUITMENT = 'Recruitment',
    RED_TOURISM_WAVE = 'Red Tourism Wave',
    SPONSORED_MOHOLE = 'Sponsored Mohole',
    SUPPORTED_RESEARCH = 'Supported Research',
    WILDLIFE_DOME = 'Wildlife Dome',
    VOTE_OF_NO_CONFIDENCE = 'Vote Of No Confidence',

    // Promo cards
    DUSK_LASER_MINING = 'Dusk Laser Mining',
    PROJECT_INSPECTION = 'Project Inspection',
    ENERGY_MARKET = 'Energy Market',
    HI_TECH_LAB = 'Hi-Tech Lab',
    INTERPLANETARY_TRADE = 'Interplanetary Trade',
    LAW_SUIT = 'Law Suit',
    MERCURIAN_ALLOYS = 'Mercurian Alloys',
    ORBITAL_CLEANUP = 'Orbital Cleanup',
    POLITICAL_ALLIANCE = 'Political Alliance',
    REGO_PLASTICS = 'Rego Plastics',
    SATURN_SURFING = 'Saturn Surfing',
    STANFORD_TORUS = 'Stanford Torus',
    ASTEROID_HOLLOWING = 'Asteroid Hollowing',
    COMET_AIMING = 'Comet Aiming',
    CRASH_SITE_CLEANUP = 'Crash Site Cleanup',
    CUTTING_EDGE_TECHNOLOGY = 'Cutting Edge Technology',
    DIRECTED_IMPACTORS = 'Directed Impactors',
    FIELD_CAPPED_CITY = 'Field-Capped City',
    MAGNETIC_SHIELD = 'Magnetic Shield',
    MELTWORKS = 'Meltworks',
    MOHOLE_LAKE = 'Mohole Lake',
    DIVERSITY_SUPPORT = 'Diversity Support',
    JOVIAN_EMBASSY = 'Jovian Embassy',
    TOPSOIL_CONTRACT = 'Topsoil Contract',
    IMPORTED_NUTRIENTS = 'Imported Nutrients',
    ASTEROID_DEFLECTION_SYSTEM = 'Asteroid Deflection System',
    SUB_CRUST_MEASUREMENTS = 'Sub-Crust Measurements',
    POTATOES = 'Potatoes',
    MEAT_INDUSTRY = 'Meat Industry',
    DEIMOS_DOWN_PROMO = 'Deimos Down:promo',
    GREAT_DAM_PROMO = 'Great Dam:promo',
    MAGNETIC_FIELD_GENERATORS_PROMO = 'Magnetic Field Generators:promo',
    ASTEROID_RIGHTS = 'Asteroid Rights',
    BIO_PRINTING_FACILITY = 'Bio Printing Facility',
    BACTOVIRAL_RESEARCH = 'Bactoviral Research',

    // Community corps
    AGRICOLA_INC = 'Agricola Inc',
    CURIOSITY_II = 'Curiosity II',
    INCITE = 'Incite',
    MIDAS = 'Midas',
    PLAYWRIGHTS = 'Playwrights',
    PROJECT_WORKSHOP = 'Project Workshop',

    // Community preludes
    VALUABLE_GASES = 'Valuable Gases',
    VENUS_FIRST = 'Venus First',
    RESEARCH_GRANT = 'Research Grant',
    AEROSPACE_MISSION = 'Aerospace Mission',
    TRADE_ADVANCE = 'Trade Advance',
    POLITICAL_UPRISING = 'Political Uprising',
    BY_ELECTION = 'By-Election',

    // Community colonies
    SCIENCE_TAG_BLANK_CARD = '',

    // Ares expansion.
    BIOENGINEERING_ENCLOSURE = 'Bioengineering Enclosure',
    BIOFERTILIZER_FACILITY = 'Bio-Fertilizer Facility',
    BUTTERFLY_EFFECT = 'Butterfly Effect',
    CAPITAL_ARES = 'Capital:ares',
    COMMERCIAL_DISTRICT_ARES = 'Commercial District:ares',
    DESPERATE_MEASURES = 'Desperate Measures',
    ECOLOGICAL_SURVEY = 'Ecological Survey',
    ECOLOGICAL_ZONE_ARES = 'Ecological Zone:ares',
    GEOLOGICAL_SURVEY = 'Geological Survey',
    INDUSTRIAL_CENTER_ARES = 'Industrial Center:ares',
    LAVA_FLOWS_ARES = 'Lava Flows:ares',
    MARKETING_EXPERTS = 'Marketing Experts',
    METALLIC_ASTEROID = 'Metallic Asteroid',
    MINING_AREA_ARES = 'Mining Area:ares',
    MINING_RIGHTS_ARES = 'Mining Rights:ares',
    MOHOLE_AREA_ARES = 'Mohole Area:ares',
    NATURAL_PRESERVE_ARES = 'Natural Preserve:ares',
    NUCLEAR_ZONE_ARES = 'Nuclear Zone:ares',
    OCEAN_CITY = 'Ocean City',
    OCEAN_FARM = 'Ocean Farm',
    OCEAN_SANCTUARY = 'Ocean Sanctuary',
    RESTRICTED_AREA_ARES = 'Restricted Area:ares',
    SOLAR_FARM = 'Solar Farm',

    // The Moon.
    MARE_NECTARIS_MINE = 'Mare Nectaris Mine',
    MARE_NUBIUM_MINE = 'Mare Nubium Mine',
    MARE_IMBRIUM_MINE = 'Mare Imbrium Mine',
    MARE_SERENITATIS_MINE = 'Mare Serenitatis Mine',
    HABITAT_14 = 'Habitat 14',
    GEODESIC_TENTS = 'Geodesic Tents',
    SPHERE_HABITATS = 'Sphere Habitats',
    THE_WOMB = 'The Womb',
    TYCHO_ROAD_NETWORK = 'Tycho Road Network',
    ARISTARCHUS_ROAD_NETWORK = 'Aristarchus Road Network',
    SINUS_IRIDIUM_ROAD_NETWORK = 'Sinus Iridium Road Network',
    MOMENTUM_VIRUM_HABITAT = 'Momentum Virium Habitat',
    LUNA_TRADE_STATION = 'Luna Trade Station',
    LUNA_MINING_HUB = 'Luna Mining Hub',
    LUNA_TRAIN_STATION = 'Luna Train Station',
    COLONIST_SHUTTLES = 'Colonist Shuttles',
    LUNAR_DUST_PROCESSING_PLANT = 'Lunar Dust Processing Plant',
    DEEP_LUNAR_MINING = 'Deep Lunar Mining',
    ANCIENT_SHIPYARDS = 'Ancient Shipyards',
    LUNA_PROJECT_OFFICE = 'Luna Project Office',
    LUNA_RESORT = 'Luna Resort',
    LUNAR_OBSERVATION_POST = 'Lunar Observation Post',
    MINING_ROBOTS_MANUF_CENTER = 'Mining Robots Manuf. Center',
    PRIDE_OF_THE_EARTH_ARKSHIP = 'Pride of the Earth Arkship',
    IRON_EXTRACTION_CENTER = 'Iron Extraction Center',
    TITANIUM_EXTRACTION_CENTER = 'Titanium Extraction Center',
    ARCHIMEDES_HYDROPONICS_STATION = 'Archimedes Hydroponics Station',
    STEEL_MARKET_MONOPOLISTS = 'Steel Market Monopolists',
    TITANIUM_MARKET_MONOPOLISTS = 'Titanium Market Monopolists',
    LUNA_STAGING_STATION = 'Luna Staging Station',
    NEW_COLONY_PLANNING_INITIAITIVES = 'New Colony Planning Initiaitives',
    AI_CONTROLLED_MINE_NETWORK = 'AI Controlled Mine Network',
    DARKSIDE_METEOR_BOMBARDMENT = 'Darkside Meteor Bombardment',
    UNDERGROUND_DETONATORS = 'Underground Detonators',
    LUNAR_TRADE_FLEET = 'Lunar Trade Fleet',
    SUBTERRANEAN_HABITATS = 'Subterranean Habitats',
    IMPROVED_MOON_CONCRETE = 'Improved Moon Concrete',
    MOONCRATE_BLOCK_FACTORY = 'Mooncrate Block Factory',
    HEAVY_DUTY_ROVERS = 'Heavy Duty Rovers',
    MICROSINGULARITY_PLANT = 'Microsingularity Plant',
    HELIOSTAT_MIRROR_ARRAY = 'Heliostat Mirror Array',
    LUNAR_SECURITY_STATIONS = 'Lunar Security Stations',
    HYPERSENSITIVE_SILICON_CHIP_FACTORY = 'Hypersensitive Silicon Chip Factory',
    COPERNICUS_SOLAR_ARRAYS = 'Copernicus Solar Arrays',
    DARKSIDE_INCUBATION_PLANT = 'Darkside Incubation Plant',
    WATER_TREATMENT_COMPLEX = 'Water Treatment Complex',
    ALGAE_BIOREACTORS = 'Algae Bioreactors',
    HE3_FUSION_PLANT = 'HE3 Fusion Plant',
    HE3_REFINERY = 'HE3 Refinery',
    HE3_LOBBYISTS = 'HE3 Lobbyists',
    REVOLTING_COLONISTS = 'Revolting Colonists',
    COSMIC_RADIATION = 'Cosmic Radiation',
    OFF_WORLD_CITY_LIVING = 'Off-World City Living',
    ROAD_PIRACY = 'Road Piracy',
    LUNAR_MINE_URBANIZATION = 'Lunar Mine Urbanization',
    THORIUM_RUSH = 'Thorium Rush',
    HE3_PRODUCTION_QUOTAS = 'HE3 Production Quotas',
    LUNA_CONFERENCE = 'Luna Conference',
    WE_GROW_AS_ONE = 'We Grow As One',
    MOONCRATE_CONVOYS_TO_MARS = 'Mooncrate Convoys To Mars',
    LUNAR_INDEPENDENCE_WAR = 'Lunar Independence War',
    AN_OFFER_YOU_CANT_REFUSE = 'An Offer You Can\'t Refuse',
    PRELIMINARY_DARKSIDE = 'Preliminary Darkside',
    HOSTILE_TAKEOVER = 'Hostile Takeover',
    SYNDICATE_PIRATE_RAIDS = 'Syndicate Pirate Raids',
    DARKSIDE_MINING_SYNDICATE = 'Darkside Mining Syndicate',
    HE3_PROPULSION = 'HE3 Propulsion',
    STAGING_STATION_BEHEMOTH = 'Staging Station "Behemoth"',
    LUNA_ARCHIVES = 'Luna Archives',
    LUNA_SENATE = 'Luna Senate',
    LUNA_POLITICAL_INSTITUTE = 'Luna Political Institute',
    COPERNICUS_TOWER = 'Copernicus Tower',
    SMALL_DUTY_ROVERS = 'Small Duty Rovers',
    LUNAR_INDUSTRY_COMPLEX = 'Lunar Industry Complex',
    DARKSIDE_OBSERVATORY = 'Darkside Observatory',
    MARTIAN_EMBASSY = 'Martian Embassy',
    EARTH_EMBASSY = 'Earth Embassy',
    ROVER_DRIVERS_UNION = 'Rover Drivers Union',
    LTF_HEADQUARTERS = 'L.T.F. Headquarters',
    DARKSIDE_SMUGGLERS_UNION = 'Darkside Smugglers\' Union',
    UNDERMOON_DRUG_LORDS_NETWORK = 'Undermoon Drug Lords Network',
    LTF_PRIVILEGES = 'L.T.F. Privileges',
    GRAND_LUNA_ACADEMY = 'Grand Luna Academy',
    LUNA_ECUMENOPOLIS = 'Luna Ecumenopolis',
    ORBITAL_POWER_GRID = 'Orbital Power Grid',
    PROCESSOR_FACTORY = 'Processor Factory',
    LUNAR_STEEL = 'Lunar Steel',
    RUST_EATING_BACTERIA = 'Rust Eating Bacteria',
    SOLAR_PANEL_FOUNDRY = 'Solar Panel Foundry',
    MOON_TETHER = 'Moon Tether',
    NANOTECH_INDUSTRIES = 'Nanotech Industries',
    TEMPEST_CONSULTANCY = 'Tempest Consultancy',
    THE_DARKSIDE_OF_THE_MOON_SYNDICATE = 'The Darkside of The Moon Syndicate',
    LUNA_HYPERLOOP_CORPORATION = 'Luna Hyperloop Corporation',
    CRESCENT_RESEARCH_ASSOCIATION = 'Crescent Research Association',
    LUNA_FIRST_INCORPORATED = 'Luna First Incorporated',
    THE_GRAND_LUNA_CAPITAL_GROUP = 'The Grand Luna Capital Group',
    INTRAGEN_SANCTUARY_HEADQUARTERS = 'Intragen Sanctuary Headquarters',
    LUNA_TRADE_FEDERATION = 'Luna Trade Federation',
    THE_ARCHAIC_FOUNDATION_INSTITUTE = 'The Archaic Foundation Institute',
    FIRST_LUNAR_SETTLEMENT = 'First Lunar Settlement',
    CORE_MINE = 'Core Mine',
    BASIC_INFRASTRUCTURE = 'Basic Infrastructure',
    LUNAR_PlANNING_OFFICE = 'Lunar Planning Office',
    MINING_COMPLEX = 'Mining Complex',
    MOON_ROAD_STANDARD_PROJECT = 'Road Infrastructure',
    MOON_MINE_STANDARD_PROJECT = 'Lunar Mine',
    MOON_COLONY_STANDARD_PROJECT = 'Lunar Habitat',
}
