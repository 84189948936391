var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getMainClasses() },
    [
      _vm.isPrelude()
        ? _c("div", { staticClass: "prelude-label" }, [_vm._v("prelude")])
        : _vm._e(),
      _vm._v(" "),
      _vm.isCorporation()
        ? _c("div", { staticClass: "corporation-label" }, [
            _vm._v("corporation")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isCorporation()
        ? _c("CardCorporationLogo", { attrs: { title: _vm.title } })
        : _c("div", { class: _vm.getClasses(_vm.title) }, [
            _vm._v(_vm._s(_vm.getCardTitleWithoutSuffix(_vm.title)))
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }