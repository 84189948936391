



































import Vue from 'vue';
import {AWARD_COSTS} from '../constants';
import {FundedAwardModel} from '../models/FundedAwardModel';
import {PreferencesManager} from './PreferencesManager';

export default Vue.extend({
  name: 'Award',
  props: {
    awards_list: {
      type: Array as () => Array<FundedAwardModel>,
    },
    show_scores: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    const showDescription: {[x: string]: boolean} = {};
    for (const award of this.awards_list) {
      showDescription[award.award.name] = false;
    }
    return {
      showDescription,
      showList: true,
    };
  },
  methods: {
    getNameCss: function(awardName: string): string {
      return (
        'ma-name ma-name--' + awardName.replace(/ /g, '-').toLowerCase()
      );
    },
    shouldShow: function(award: FundedAwardModel): boolean {
      return this.showDescription[award.award.name] === true;
    },
    shouldShowList: function(): boolean {
      return this.showList;
    },
    toggle: function(award: FundedAwardModel) {
      this.showDescription[award.award.name] = !this.showDescription[award.award.name];
    },
    toggleList: function() {
      this.showList = !this.showList;
    },
    getAvailableAwardSpots: function(): Array<number> {
      let numFundedAwards = 0;
      this.awards_list.forEach((award)=>{
        if (award.player_name) {
          numFundedAwards++;
        }
      });
      return AWARD_COSTS.slice(numFundedAwards);
    },
    isLearnerModeOn: function(): boolean {
      return PreferencesManager.loadBoolean('learner_mode');
    },
  },
});
