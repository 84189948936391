var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "help-iconology-container" }, [
    _c("div", { staticClass: "help-icons-column" }, [
      _c("div", [
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icons-section-heading"
          },
          [_vm._v("Card Tags")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-building" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Building")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-space" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Space")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-science" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Science")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-power" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Power")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-earth" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Earth")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-jovian" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Jovian")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-venus" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Venus")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "expansion-icon expansion-icon-venus" })
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-plant" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Plant")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-microbe" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Microbe")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-animal" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Animal")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-city" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("City")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-moon" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Moon")]
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-wild" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Wild")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "resource-tag tag-event" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Event")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "help-icons-column" }, [
      _c("div", [
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icons-section-heading"
          },
          [_vm._v("Resources")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [{ name: "i18n", rawName: "v-i18n" }],
          staticClass: "help-icon-sublabel"
        },
        [_vm._v("\n            Standard Resources\n        ")]
      ),
      _vm._v(" "),
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("MegaCredits (M€)")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource steel" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Steel")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource titanium" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Titanium")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource plant" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Plant")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource energy" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Energy")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource heat" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Heat")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [{ name: "i18n", rawName: "v-i18n" }],
          staticClass: "help-icon-sublabel"
        },
        [_vm._v("\n            Resources on Cards\n        ")]
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource animal" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Animal")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource microbe" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Microbe")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource science" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Science")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource floater" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Floater")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "help-icon-resource asteroid" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Asteroid")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "help-icons-column" }, [
      _c("div", [
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icons-section-heading"
          },
          [_vm._v("Tiles")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile greenery-no-O2-tile" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Greenery")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile city-tile" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("City")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile ocean-tile" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Ocean")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile special-tile" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Special")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "help-icons-column" }, [
      _c("div", [
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icons-section-heading"
          },
          [_vm._v("Global Parameters")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile help-icon-param-box-temperature" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Temperature")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile help-icon-param-box-oxygen" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Oxygen")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile help-icon-param-box-ocean" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Oceans")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tile help-icon-param-box-venus" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Venus")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "help-icons-column" }, [
      _c("div", [
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icons-section-heading"
          },
          [_vm._v("Others")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Victory Point (VP)")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Terraform Rating (TR)")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Project Card")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(4),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Colony")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(5),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Trade")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(6),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Trade Fleet")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(7),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Delegate")]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(8),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "help-icon-label"
          },
          [_vm._v("Influence")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-icon-resource money" }, [
      _c("div", { staticClass: "help-icon-mc-font" }, [_vm._v("€")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "help-icon-victory-point" }, [_vm._v("?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "tile rating" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "help-icon-card card" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "tile colony" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "tile trade" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "tile fleet" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "help-icon-delegate" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-other-symbols" }, [
      _c("div", { staticClass: "help-icon-influence influence" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }