







import Vue from 'vue';
import {CardRenderer} from '../../cards/render/CardRenderer';
import CardRowData from './CardRowData.vue';

export default Vue.extend({
  name: 'CardRenderData',
  props: {
    renderData: {
      type: Object as () => CardRenderer,
      required: true,
    },
  },
  components: {
    CardRowData,
  },
});

