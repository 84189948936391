var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isItem()
    ? _c("CardRenderItemComponent", { attrs: { item: _vm.componentData } })
    : _vm.isSymbol()
    ? _c("CardRenderSymbolComponent", { attrs: { item: _vm.componentData } })
    : _vm.isProduction(_vm.componentData)
    ? _c("CardProductionBoxComponent", {
        attrs: { rows: _vm.componentData.rows }
      })
    : _vm.isEffect()
    ? _c("CardRenderEffectBoxComponent", {
        attrs: { effectData: _vm.componentData }
      })
    : _vm.isTile()
    ? _c("CardRenderTileComponent", { attrs: { item: _vm.componentData } })
    : _vm.isDescription()
    ? _c("CardDescription", { attrs: { item: _vm.componentData } })
    : _vm.isCorpBox(_vm.componentData)
    ? _c("CardRenderCorpBoxComponent", {
        attrs: { rows: _vm.componentData.rows, label: _vm.corpBoxLabel() }
      })
    : _c("div", [_vm._v("n/a")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }