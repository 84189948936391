








import Vue from 'vue';
import {Shared} from './Shared';

export default Vue.extend({
  name: 'OverviewSettings',
  methods: {
    toggleTagsView: function() {
      (this.$root as any).setVisibilityState(
        'tags_concise',
        !(this.$root as any).getVisibilityState('tags_concise'),
      );
    },
    getTagToggleLabel: function(): string {
      return Shared.isTagsViewConcise(this.$root) ? 'full' : 'concise';
    },
  },
});
