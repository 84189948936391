var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requirements.hasParty()
    ? _c("div", { class: _vm.getClasses() }, [
        _c("span", { staticClass: "party" }, [
          _vm._v(_vm._s(_vm.requirements.getRequirementsText()))
        ])
      ])
    : _vm.requirements.hasPlantsRemoved()
    ? _c("div", { class: _vm.getClasses() }, [
        _c("div", { staticClass: "card-special card-minus" }),
        _vm._v(" "),
        _c("div", {
          staticClass: "card-resource card-resource-plant red-outline"
        })
      ])
    : _c("div", { class: _vm.getClasses() }, [
        _vm._v(_vm._s(_vm.requirements.getRequirementsText()))
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }