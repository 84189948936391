var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "log-container" }, [
    _c("div", { staticClass: "log-generations" }, [
      _c("h2", { class: _vm.getTitleClasses() }, [
        _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
          _vm._v("Game log")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "log-gen-title" }, [_vm._v("Gen: ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "log-gen-numbers" },
        _vm._l(_vm.getGenerationsRange(), function(n) {
          return _c(
            "div",
            {
              key: n,
              class: _vm.getClassesGenIndicator(n),
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.selectGeneration(n)
                }
              }
            },
            [_vm._v("\n        " + _vm._s(n) + "\n      ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.players.length === 1
        ? _c("span", { staticClass: "label-additional" }, [
            _c("span", { class: _vm.lastGenerationClass }, [
              _vm._v("of " + _vm._s(this.lastSoloGeneration))
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel log-panel" }, [
      _c(
        "div",
        { staticClass: "panel-body", attrs: { id: "logpanel-scrollable" } },
        [
          _vm.messages
            ? _c(
                "ul",
                _vm._l(_vm.messages, function(message, index) {
                  return _c("li", {
                    key: index,
                    domProps: { innerHTML: _vm._s(_vm.messageToHTML(message)) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.messageClicked(message)
                      }
                    }
                  })
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _vm.cards.length > 0 || _vm.globalEventNames.length > 0
      ? _c(
          "div",
          { staticClass: "card-panel" },
          [
            _c("Button", {
              attrs: {
                size: "big",
                type: "close",
                disableOnServerBusy: false,
                onClick: _vm.hideMe,
                align: "right"
              }
            }),
            _vm._v(" "),
            _vm._l(_vm.cards, function(card) {
              return _c(
                "div",
                {
                  key: card,
                  staticClass: "cardbox",
                  attrs: { id: "log_panel_card" }
                },
                [_c("Card", { attrs: { card: { name: card } } })],
                1
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.globalEventNames, function(globalEventName) {
              return _c(
                "div",
                {
                  key: globalEventName,
                  staticClass: "cardbox",
                  attrs: { id: "log_panel_card" }
                },
                [
                  _c("global-event", {
                    attrs: {
                      globalEvent: _vm.getGlobalEvent(globalEventName),
                      type: "prior"
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }