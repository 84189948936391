var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { class: _vm.getClasses() }, [
        _vm._v(_vm._s(_vm.amount === null ? 0 : _vm.amount))
      ]),
      _vm._v(" "),
      _vm.displayTwoCosts()
        ? [
            _c("div", { staticClass: "card-cost-transition" }),
            _vm._v(" "),
            _c("div", { staticClass: "card-old-cost" }, [
              _vm._v(_vm._s(_vm.newCost))
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }