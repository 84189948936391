var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game_end_cont", attrs: { id: "game-end" } },
    [
      _c("h1", [_vm._v(_vm._s(_vm.constants.APP_NAME) + " - Game finished!")]),
      _vm._v(" "),
      _c("div", { staticClass: "game_end" }, [
        _vm.isSoloGame()
          ? _c("div", [
              _vm.player.game.isSoloModeWin
                ? _c("div", [
                    _c("div", { staticClass: "game_end_success" }, [
                      _c(
                        "h2",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [_vm._v("You win!")]
                      ),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [{ name: "i18n", rawName: "v-i18n" }],
                          staticClass: "game_end_notice"
                        },
                        [
                          _vm._v(
                            "\n                        But it isn't the reason to stop making Mars better.\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("ul", { staticClass: "game_end_list" }, [
                        _c(
                          "li",
                          { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                          [_vm._v("Try to win with expansions enabled")]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                          [
                            _vm._v(
                              "Try to win before the last generation comes"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "span",
                            {
                              directives: [{ name: "i18n", rawName: "v-i18n" }]
                            },
                            [_vm._v("Can you get")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.player.victoryPointsBreakdown.total + 10
                              )
                          ),
                          _c(
                            "span",
                            {
                              directives: [{ name: "i18n", rawName: "v-i18n" }]
                            },
                            [_vm._v("+ Victory Points?")]
                          )
                        ])
                      ])
                    ])
                  ])
                : _c("div", [
                    _c("div", { staticClass: "game_end_fail" }, [
                      _c(
                        "h2",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [_vm._v("Sorry, you lose.")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [{ name: "i18n", rawName: "v-i18n" }],
                          staticClass: "game_end_notice"
                        },
                        [
                          _vm._v(
                            "\n                        Next time you will get more luck!"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                        Also, take into account these small hints to win:\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          directives: [{ name: "i18n", rawName: "v-i18n" }],
                          staticClass: "game_end_list"
                        },
                        [
                          _c("li", [
                            _vm._v(
                              "Concentrate more on Global parameters, not on Victory Points"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("Don't be greedy on cards selection")
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Try to increase Heat production, not Megacredits"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("Try to start with Beginner corporation")
                          ])
                        ]
                      )
                    ])
                  ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "game_end_go_home" }, [
          _c(
            "a",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              attrs: { href: "/" }
            },
            [
              _c("Button", { attrs: { size: "big", type: "back" } }),
              _vm._v("\n                Go to main page\n            ")
            ],
            1
          )
        ]),
        _vm._v(" "),
        !_vm.isSoloGame() || _vm.player.game.isSoloModeWin
          ? _c(
              "div",
              { staticClass: "game-end-winer-announcement" },
              [
                _vm._l(_vm.getWinners(), function(p) {
                  return _c("span", { key: p.color }, [
                    _c(
                      "span",
                      {
                        class:
                          "log-player " +
                          _vm.getEndGamePlayerRowColorClass(p.color)
                      },
                      [_vm._v(_vm._s(p.name))]
                    )
                  ])
                }),
                _vm._v(" won!\n        ")
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "game_end_victory_points" }, [
          _c("h2", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Victory points breakdown after"),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.player.game.generation) + " ")
            ]),
            _vm._v("generations")
          ]),
          _vm._v(" "),
          _c("table", { staticClass: "table game_end_table" }, [
            _c("thead", [
              _c("tr", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _vm.player.game.moon !== undefined
                  ? _c("th", [
                      _c("div", { staticClass: "table-moon-road-tile" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.player.game.moon !== undefined
                  ? _c("th", [
                      _c("div", { staticClass: "table-moon-colony-tile" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.player.game.moon !== undefined
                  ? _c("th", [
                      _c("div", { staticClass: "table-moon-mine-tile" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(7),
                _vm._v(" "),
                _vm._m(8),
                _vm._v(" "),
                _vm._m(9),
                _vm._v(" "),
                _vm.player.game.gameOptions.showTimers
                  ? _c("th", { staticClass: "clock-icon" }, [_vm._v("🕑")])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.getSortedPlayers(), function(p) {
                return _c(
                  "tr",
                  {
                    key: p.color,
                    class: _vm.getEndGamePlayerRowColorClass(p.color)
                  },
                  [
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: { href: "/player?id=" + p.id + "&noredirect" }
                        },
                        [_vm._v(_vm._s(p.name))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "column-corporation" }, [
                        _vm._v(
                          _vm._s(
                            p.corporationCard === undefined
                              ? ""
                              : p.corporationCard.name
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(p.victoryPointsBreakdown.terraformRating))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(p.victoryPointsBreakdown.milestones))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(p.victoryPointsBreakdown.awards))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(p.victoryPointsBreakdown.greenery))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(p.victoryPointsBreakdown.city))]),
                    _vm._v(" "),
                    _vm.player.game.moon !== undefined
                      ? _c("td", [
                          _vm._v(_vm._s(p.victoryPointsBreakdown.moonRoads))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.player.game.moon !== undefined
                      ? _c("td", [
                          _vm._v(_vm._s(p.victoryPointsBreakdown.moonColonies))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.player.game.moon !== undefined
                      ? _c("td", [
                          _vm._v(_vm._s(p.victoryPointsBreakdown.moonMines))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(p.victoryPointsBreakdown.victoryPoints))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "game-end-total" }, [
                      _vm._v(_vm._s(p.victoryPointsBreakdown.total))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "game-end-mc" }, [
                      _c("div", [_vm._v(_vm._s(p.megaCredits))])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm.player.game.gameOptions.showTimers
                        ? _c("div", { staticClass: "game-end-timer" }, [
                            _vm._v(_vm._s(_vm.getTimer(p)))
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("h2", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Victory points details")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "game-end-flexrow" },
            _vm._l(_vm.getSortedPlayers(), function(p) {
              return _c(
                "div",
                { key: p.color, staticClass: "game-end-column" },
                [
                  _c(
                    "div",
                    { staticClass: "game-end-winer-scorebreak-player-title" },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "game-end-player " +
                            _vm.getEndGamePlayerRowColorClass(p.color)
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/player?id=" + p.id + "&noredirect"
                              }
                            },
                            [_vm._v(_vm._s(p.name))]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(p.victoryPointsBreakdown.detailsCards, function(v) {
                    return _c("div", { key: v.cardName }, [
                      _c("div", { staticClass: "game-end-column-row" }, [
                        _c("div", { staticClass: "game-end-column-vp" }, [
                          _vm._v(_vm._s(v.victoryPoint))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "game-end-column-text" }, [
                          _vm._v(_vm._s(v.cardName))
                        ])
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm._m(10, true),
                  _vm._v(" "),
                  _vm._l(p.victoryPointsBreakdown.detailsMilestones, function(
                    v
                  ) {
                    return _c("div", { key: v }, [
                      _c("div", { staticClass: "game-end-column-row" }, [
                        _c("div", { staticClass: "game-end-column-vp" }, [
                          _vm._v(_vm._s(v.split(":", 2)[1]))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "game-end-column-text" }, [
                          _vm._v(_vm._s(v.split(":", 2)[0]))
                        ])
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(p.victoryPointsBreakdown.detailsAwards, function(v) {
                    return _c("div", { key: v }, [
                      _c("div", { staticClass: "game-end-column-row" }, [
                        _c("div", { staticClass: "game-end-column-vp" }, [
                          _vm._v(_vm._s(v.split(":", 2)[1]))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "game-end-column-text" }, [
                          _vm._v(_vm._s(v.split(":", 2)[0]))
                        ])
                      ])
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "game-end-flexrow" }, [
          _c(
            "div",
            { staticClass: "game_end_block--board game-end-column" },
            [
              _c("h2", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Final situation on the board")
              ]),
              _vm._v(" "),
              _c("board", {
                attrs: {
                  spaces: _vm.player.game.spaces,
                  venusNextExtension:
                    _vm.player.game.gameOptions.venusNextExtension,
                  venusScaleLevel: _vm.player.game.venusScaleLevel,
                  aresExtension: _vm.player.game.gameOptions.aresExtension,
                  boardName: _vm.player.game.gameOptions.boardName,
                  oceans_count: _vm.player.game.oceans,
                  oxygen_level: _vm.player.game.oxygenLevel,
                  temperature: _vm.player.game.temperature
                }
              }),
              _vm._v(" "),
              _vm.player.game.gameOptions.moonExpansion
                ? _c("MoonBoard", { attrs: { model: _vm.player.game.moon } })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "game_end_block--log game-end-column" },
            [
              _c("log-panel", {
                attrs: {
                  color: _vm.player.color,
                  generation: _vm.player.game.generation,
                  id: _vm.player.id,
                  lastSoloGeneration: _vm.player.game.lastSoloGeneration,
                  players: _vm.player.players
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game_end_solo_img" }, [
      _c("img", { attrs: { src: "/assets/solo_win.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("div", { staticClass: "card-delegate" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("div", { staticClass: "tr" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c(
        "div",
        { staticClass: "m-and-a", attrs: { title: "Milestones points" } },
        [_vm._v("M")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c("div", { staticClass: "m-and-a", attrs: { title: "Awards points" } }, [
        _vm._v("A")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("div", { staticClass: "table-forest-tile" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("div", { staticClass: "table-city-tile" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("div", { staticClass: "vp" }, [_vm._v("VP")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "game-end-total" }, [
      _c("div", { staticClass: "game-end-total-column" }, [_vm._v("Total")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("div", { staticClass: "mc-icon" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game-end-column-row" }, [
      _c("div", { staticClass: "game-end-column-vp" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("div", { staticClass: "game-end-column-text" }, [_vm._v(" ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }