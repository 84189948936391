var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wf-component" }, [
    _vm.hazardData.erosionOceanCount.available
      ? _c(
          "div",
          [
            _vm._v("\n        Reveal erosions at: \n        "),
            _vm._l(_vm.ADJUSTMENT_RANGE, function(value) {
              return _c(
                "label",
                {
                  key: value,
                  staticClass:
                    "form-radio form-inline ares-global-parameter-label"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lowOceanDelta,
                        expression: "lowOceanDelta"
                      }
                    ],
                    attrs: { type: "radio", name: "lowOceanDelta" },
                    domProps: {
                      value: value,
                      checked: _vm._q(_vm.lowOceanDelta, value)
                    },
                    on: {
                      change: function($event) {
                        _vm.lowOceanDelta = value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "form-icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ares-global-parameter-option" }, [
                    _vm._v(
                      _vm._s(
                        value + _vm.hazardData.erosionOceanCount.threshold
                      ) + " oceans."
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hazardData.removeDustStormsOceanCount.available
      ? _c(
          "div",
          [
            _vm._v("\n        Remove dust storms at: \n        "),
            _vm._l(_vm.ADJUSTMENT_RANGE, function(value) {
              return _c(
                "label",
                {
                  key: value,
                  staticClass:
                    "form-radio form-inline ares-global-parameter-label"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.highOceanDelta,
                        expression: "highOceanDelta"
                      }
                    ],
                    attrs: { type: "radio", name: "highOceanDelta" },
                    domProps: {
                      value: value,
                      checked: _vm._q(_vm.highOceanDelta, value)
                    },
                    on: {
                      change: function($event) {
                        _vm.highOceanDelta = value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "form-icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ares-global-parameter-option" }, [
                    _vm._v(
                      _vm._s(
                        value +
                          _vm.hazardData.removeDustStormsOceanCount.threshold
                      ) + " oceans"
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hazardData.severeErosionTemperature.available
      ? _c(
          "div",
          [
            _vm._v("\n        Amplify erosions at: \n        "),
            _vm._l(_vm.ADJUSTMENT_RANGE, function(value) {
              return _c(
                "label",
                {
                  key: value,
                  staticClass:
                    "form-radio form-inline ares-global-parameter-label"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.temperatureDelta,
                        expression: "temperatureDelta"
                      }
                    ],
                    attrs: { type: "radio", name: "temperatureDelta" },
                    domProps: {
                      value: value,
                      checked: _vm._q(_vm.temperatureDelta, value)
                    },
                    on: {
                      change: function($event) {
                        _vm.temperatureDelta = value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "form-icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ares-global-parameter-option" }, [
                    _vm._v(
                      _vm._s(
                        2 * value +
                          _vm.hazardData.severeErosionTemperature.threshold
                      ) + " °C"
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hazardData.severeDustStormOxygen.available
      ? _c(
          "div",
          [
            _vm._v("\n        Amplify dust storms at: \n        "),
            _vm._l(_vm.ADJUSTMENT_RANGE, function(value) {
              return _c(
                "label",
                {
                  key: value,
                  staticClass:
                    "form-radio form-inline ares-global-parameter-label"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oxygenDelta,
                        expression: "oxygenDelta"
                      }
                    ],
                    attrs: { type: "radio", name: "oxygenDelta" },
                    domProps: {
                      value: value,
                      checked: _vm._q(_vm.oxygenDelta, value)
                    },
                    on: {
                      change: function($event) {
                        _vm.oxygenDelta = value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "form-icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ares-global-parameter-option" }, [
                    _vm._v(
                      _vm._s(
                        value + _vm.hazardData.severeDustStormOxygen.threshold
                      ) + "% oxygen"
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showsave === true
      ? _c("div", { staticClass: "nofloat" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-submit",
              on: { click: _vm.saveData }
            },
            [_vm._v(_vm._s(_vm.playerinput.buttonLabel))]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }