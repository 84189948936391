var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.getClass() }, [
    _c(
      "div",
      {
        directives: [{ name: "i18n", rawName: "v-i18n" }],
        staticClass: "event-party event-party--top",
        class: _vm.partyNameClass(_vm.globalEvent.revealed)
      },
      [_vm._v(_vm._s(_vm.globalEvent.revealed))]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [{ name: "i18n", rawName: "v-i18n" }],
        staticClass: "event-party event-party--bottom",
        class: _vm.partyNameClass(_vm.globalEvent.current)
      },
      [_vm._v(_vm._s(_vm.globalEvent.current))]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "event-content" }, [
      _c(
        "div",
        {
          directives: [{ name: "i18n", rawName: "v-i18n" }],
          staticClass: "event-text"
        },
        [_vm._v(_vm._s(_vm.globalEvent.description))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }