var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getCardClasses(_vm.card) },
    [
      _c(
        "div",
        {
          directives: [{ name: "i18n", rawName: "v-i18n" }],
          staticClass: "card-content-wrapper"
        },
        [
          !_vm.isStandardProject()
            ? _c(
                "div",
                { staticClass: "card-cost-and-tags" },
                [
                  _c("CardCost", {
                    attrs: {
                      amount: _vm.getCost(),
                      newCost: _vm.getReducedCost()
                    }
                  }),
                  _vm._v(" "),
                  _c("CardTags", { attrs: { tags: _vm.getTags() } })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("CardTitle", {
            attrs: { title: _vm.card.name, type: _vm.getCardType() }
          }),
          _vm._v(" "),
          _vm.getCardMetadata() !== undefined
            ? _c("CardContent", {
                attrs: {
                  metadata: _vm.getCardMetadata(),
                  requirements: _vm.getCardRequirements(),
                  isCorporation: _vm.isCorporationCard()
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.getCardMetadata() !== undefined
            ? _c("CardNumber", { attrs: { number: _vm.getCardNumber() } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("CardExpansion", {
        attrs: {
          expansion: _vm.getCardExpansion(),
          isCorporation: _vm.isCorporationCard()
        }
      }),
      _vm._v(" "),
      _vm.card.resources !== undefined
        ? _c("CardResourceCounter", {
            attrs: { amount: _vm.getResourceAmount(_vm.card) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("CardExtraContent", { attrs: { card: _vm.card } }),
      _vm._v(" "),
      _vm.owner !== undefined
        ? [
            _c(
              "div",
              {
                class:
                  "card-owner-label player_translucent_bg_color_" +
                  _vm.owner.color
              },
              [_vm._v("\n        " + _vm._s(_vm.owner.name) + "\n      ")]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }