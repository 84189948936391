var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-standard-projects-container" },
    [
      _c("h2", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
        _vm._v("Standard Projects")
      ]),
      _vm._v(" "),
      _vm._l(_vm.getBasicStandardProjects(), function(card) {
        return _c(
          "div",
          { key: card, staticClass: "cardbox" },
          [_c("Card", { attrs: { card: { name: card } } })],
          1
        )
      }),
      _vm._v(" "),
      _c("h2", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
        _vm._v("Standard Projects from Expansions and Solo Mode")
      ]),
      _vm._v(" "),
      _vm._l(_vm.getExpansionStandardProjects(), function(card) {
        return _c(
          "div",
          { key: card, staticClass: "cardbox" },
          [_c("Card", { attrs: { card: { name: card } } })],
          1
        )
      }),
      _vm._v(" "),
      _c("h2", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
        _vm._v("Standard Project from Fan-made Expansions")
      ]),
      _vm._v(" "),
      _vm._l(_vm.getFanMadeStandardProjects(), function(card) {
        return _c(
          "div",
          { key: card, staticClass: "cardbox" },
          [_c("Card", { attrs: { card: { name: card } } })],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }