











import Vue from 'vue';
import {PreferencesManager} from './../PreferencesManager';

export default Vue.extend({
  name: 'CardCost',
  props: {
    amount: {
      type: Number as () => number | undefined,
    },
    newCost: {
      type: Number as () => number | undefined,
    },
  },
  methods: {
    getClasses: function(): string {
      const classes = ['card-cost'];
      if (this.amount === undefined) {
        classes.push('visibility-hidden');
      }
      return classes.join(' ');
    },
    displayTwoCosts: function(): boolean {
      const hideDiscount = PreferencesManager.loadBoolean('hide_discount_on_cards');
      return this.newCost !== undefined && this.newCost !== this.amount && !hideDiscount;
    },
  },
});

