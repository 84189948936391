


































import Vue from 'vue';
import {MILESTONE_COST, MAX_MILESTONES} from '../constants';
import {ClaimedMilestoneModel} from '../models/ClaimedMilestoneModel';
import {PreferencesManager} from './PreferencesManager';

export default Vue.extend({
  name: 'Milestone',
  props: {
    milestones_list: {
      type: Array as () => Array<ClaimedMilestoneModel>,
    },
    show_scores: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    const showDescription: {[x: string]: boolean} = {};
    for (const milestone of this.milestones_list) {
      showDescription[milestone.milestone.name] = false;
    }
    return {
      showList: true,
      showDescription,
    };
  },
  methods: {
    getNameCss: function(milestoneName: string): string {
      return (
        'ma-name ma-name--' + milestoneName.replace(/ /g, '-').toLowerCase()
      );
    },
    shouldShow: function(milestone: ClaimedMilestoneModel): boolean {
      return this.showDescription[milestone.milestone.name] === true;
    },
    shouldShowList: function(): boolean {
      return this.showList;
    },
    toggle: function(milestone: ClaimedMilestoneModel) {
      this.showDescription[milestone.milestone.name] = !this.showDescription[milestone.milestone.name];
    },
    toggleList: function() {
      this.showList = !this.showList;
    },
    getAvailableMilestoneSpots: function(): Array<number> {
      const count = this.milestones_list.filter((milestone) => milestone.player_name).length;
      return Array(MAX_MILESTONES - count).fill(MILESTONE_COST);
    },
    isLearnerModeOn: function(): boolean {
      return PreferencesManager.load('learner_mode') === '1';
    },
  },
});
