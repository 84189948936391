var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "i18n", rawName: "v-i18n" }],
      staticClass: "filterDiv colony-card colonies",
      class: _vm.colony.name + "-background"
    },
    [
      _vm.colony.visitor !== undefined
        ? _c("div", { staticClass: "colony-spaceship" }, [
            _c("div", {
              class: "colonies-fleet colonies-fleet-" + _vm.colony.visitor
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.colony.isActive
        ? _c("div", {
            staticClass: "colony_cube",
            style:
              "margin-left:" +
              _vm.getCubeXPosition(_vm.colony) +
              "px; margin-top:" +
              _vm.getCubeYPosition(_vm.colony, true) +
              "px;"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.colony.colonies.length > 0
        ? _c(
            "div",
            {
              staticClass: "occupied-colony-space",
              style:
                "margin-left: " +
                _vm.getColonyXPosition(0) +
                "px;  margin-top:" +
                _vm.getCubeYPosition(_vm.colony) +
                "px;"
            },
            [
              _c("div", {
                class:
                  "board-cube colony-cube board-cube--" + _vm.colony.colonies[0]
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.colony.colonies.length > 1
        ? _c(
            "div",
            {
              staticClass: "occupied-colony-space",
              style:
                "margin-left: " +
                _vm.getColonyXPosition(1) +
                "px;  margin-top:" +
                _vm.getCubeYPosition(_vm.colony) +
                "px;"
            },
            [
              _c("div", {
                class:
                  "board-cube colony-cube board-cube--" + _vm.colony.colonies[1]
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.colony.colonies.length > 2
        ? _c(
            "div",
            {
              staticClass: "occupied-colony-space",
              style:
                "margin-left: " +
                _vm.getColonyXPosition(2) +
                "px;  margin-top:" +
                _vm.getCubeYPosition(_vm.colony) +
                "px;"
            },
            [
              _c("div", {
                class:
                  "board-cube colony-cube board-cube--" + _vm.colony.colonies[2]
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "colony-card-title-div" }, [
        _c(
          "span",
          {
            staticClass: "colony-card-title-span",
            class: _vm.colony.name + "-title"
          },
          [_vm._v(_vm._s(_vm.colony.name))]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "colony-content",
          style: "margin-top: " + _vm.getColonyContentOffset(_vm.colony) + "px;"
        },
        [
          _vm.colony.name === _vm.getGanymede()
            ? _c("div", { staticClass: "resource plant" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEuropa()
            ? _c("div", { staticClass: "resource money" }, [_vm._v("1")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitan()
            ? _c("div", { staticClass: "resource floater" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEnceladus()
            ? _c("div", { staticClass: "resource microbe" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCallisto()
            ? _c("div", { staticClass: "resource energy" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCallisto()
            ? _c("div", { staticClass: "resource energy" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCallisto()
            ? _c("div", { staticClass: "resource energy" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTriton()
            ? _c("div", { staticClass: "resource titanium" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMiranda()
            ? _c("div", {
                staticClass: "resource card card-with-border",
                staticStyle: { transform: "scale(0.8)" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCeres()
            ? _c("div", { staticClass: "resource steel" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCeres()
            ? _c("div", { staticClass: "resource steel" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIo()
            ? _c("div", { staticClass: "resource heat" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIo()
            ? _c("div", { staticClass: "resource heat" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLuna()
            ? _c("div", { staticClass: "resource money" }, [_vm._v("2")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("div", {
                staticClass: "resource card card-with-border",
                staticStyle: { transform: "scale(0.8)" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("span", { staticClass: "white-char" }, [_vm._v(":")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("div", { staticClass: "resource money" }, [_vm._v("-1")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMercury()
            ? _c("div", { staticClass: "resource money" }, [_vm._v("2")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getHygiea()
            ? _c("div", { staticClass: "resource money" }, [_vm._v("3")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania()
            ? _c("div", { staticClass: "resource money" }, [_vm._v("-3")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getVenus()
            ? _c(
                "div",
                {
                  staticClass: "resource",
                  staticStyle: {
                    background: "white",
                    margin: "15px 10px 10px 20px"
                  }
                },
                [
                  _vm._v("?"),
                  _c("div", {
                    staticClass: "card-icon tag-venus",
                    staticStyle: {
                      color: "white",
                      "margin-top": "-36px",
                      "margin-left": "16px"
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPallas()
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _c("div", { staticClass: "resource money" }, [_vm._v("1")]),
                _vm._v(" / party "),
                _c("div", { staticClass: "delegate" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-left": "10px",
                    "font-size": "14px"
                  }
                },
                [_vm._v("REVEAL TOP CARD OF DECK.")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c("span", [_c("br")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c(
                "span",
                { staticStyle: { "font-size": "14px", "margin-left": "10px" } },
                [_vm._v("BUY OR DISCARD IT.")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c(
                "span",
                {
                  staticClass: "white-char",
                  staticStyle: { "margin-left": "5px" }
                },
                [_vm._v("+")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c("div", {
                staticClass: "resource card card-with-border",
                staticStyle: { transform: "scale(0.8)", "margin-left": "-2px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c("span", { staticClass: "white-char" }, [_vm._v("-")])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c("div", {
                staticClass: "resource card card-with-border",
                staticStyle: { transform: "scale(0.8)", "margin-left": "-2px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name !== _vm.getTitania()
            ? _c("span", { staticClass: "colony-background-color" }, [
                _vm._v("\n        Colony Bonus\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania()
            ? _c("span", { staticClass: "colony-background-color" }, [
                _vm._v("\n        Colony Fee\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.colony.name === _vm.getGanymede()
            ? _c("div", {
                staticClass: "resource plant",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitan()
            ? _c("div", {
                staticClass: "resource floater",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEnceladus()
            ? _c("div", {
                staticClass: "resource microbe",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCallisto()
            ? _c("div", {
                staticClass: "resource energy",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTriton()
            ? _c("div", {
                staticClass: "resource titanium",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCeres()
            ? _c("div", {
                staticClass: "resource steel",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLuna()
            ? _c(
                "div",
                {
                  staticClass: "resource money",
                  staticStyle: { "margin-left": "20px" }
                },
                [_vm._v(" ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("div", {
                staticClass: "tile rating",
                staticStyle: {
                  "margin-left": "20px",
                  transform: "scale(0.8)",
                  "margin-top": "-10px"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIo()
            ? _c("div", {
                staticClass: "resource heat",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMiranda()
            ? _c("div", {
                staticClass: "resource animal",
                staticStyle: { "margin-left": "20px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c("div", {
                staticClass: "resource card card-with-border",
                staticStyle: {
                  "margin-left": "20px",
                  transform: "scale(0.8)",
                  "margin-top": "-8px"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEuropa()
            ? _c("div", {
                staticStyle: {
                  height: "20px",
                  visibility: "hidden",
                  display: "block"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name !== _vm.getEuropa() &&
          _vm.colony.name !== _vm.getMercury() &&
          _vm.colony.name !== _vm.getIapetus() &&
          _vm.colony.name !== _vm.getHygiea() &&
          _vm.colony.name !== _vm.getTitania() &&
          _vm.colony.name !== _vm.getVenus() &&
          _vm.colony.name !== _vm.getLeavitt() &&
          _vm.colony.name !== _vm.getPallas()
            ? _c("div", { staticClass: "white-x" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("div", {
                staticClass: "white-x",
                staticStyle: { "margin-left": "-42px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania()
            ? _c("div", {
                staticClass: "white-x",
                staticStyle: { "margin-left": "42px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania()
            ? _c(
                "div",
                {
                  staticClass: "points points-big",
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-top": "-53px",
                    transform: "scale(0.5)",
                    height: "50px",
                    width: "50px"
                  }
                },
                [_vm._v(" ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPallas()
            ? _c("div", {
                staticClass: "white-x",
                staticStyle: { "margin-left": "52px", "margin-right": "-30px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPallas()
            ? _c("div", {
                staticClass: "delegate",
                staticStyle: { "margin-top": "-23px", "margin-right": "5px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getVenus()
            ? _c("div", {
                staticClass: "white-x",
                staticStyle: { "margin-left": "45px", "margin-bottom": "4px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getVenus()
            ? _c(
                "div",
                {
                  staticClass: "resource",
                  staticStyle: {
                    background: "white",
                    margin: "10px 10px 10px -20px"
                  }
                },
                [
                  _vm._v("?"),
                  _c("div", {
                    staticClass: "card-icon tag-venus",
                    staticStyle: {
                      color: "white",
                      "margin-top": "-36px",
                      "margin-left": "16px"
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c("div", {
                staticClass: "resource card",
                staticStyle: { "margin-left": "5px", transform: "scale(0.8)" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name !== _vm.getEuropa() &&
          _vm.colony.name !== _vm.getPluto() &&
          _vm.colony.name !== _vm.getMercury() &&
          _vm.colony.name !== _vm.getIapetus() &&
          _vm.colony.name !== _vm.getHygiea() &&
          _vm.colony.name !== _vm.getTitania() &&
          _vm.colony.name !== _vm.getLeavitt() &&
          _vm.colony.name !== _vm.getPallas()
            ? _c("span", { staticClass: "colony-background-color" }, [
                _vm._v("\n        Trade Income\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: { position: "relative", top: "-3px" }
                },
                [_vm._v("\n        Trade Income\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEuropa()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: {
                    "margin-left": "3px",
                    position: "relative",
                    top: "-12px"
                  }
                },
                [
                  _vm._v(
                    "\n        Trade Income: Gain the indicated production\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: {
                    position: "relative",
                    top: "-8px",
                    left: "30px"
                  }
                },
                [_vm._v("\n        Trade Income\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania() ||
          _vm.colony.name === _vm.getPallas()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: {
                    position: "relative",
                    top: "-14px",
                    left: "12px"
                  }
                },
                [_vm._v("\n        Trade Income\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMercury()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: { "margin-left": "3px" }
                },
                [_vm._v("\n        Trade Income\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getHygiea()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: { "margin-left": "3px" }
                },
                [
                  _vm._v(
                    "\n        Trade Income: Steal 3 indicated resources\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c(
                "span",
                {
                  staticClass: "colony-background-color",
                  staticStyle: { "margin-left": "3px" }
                },
                [
                  _vm._v(
                    "\n        Trade Income: Draw X cards and keep 1\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEnceladus()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEnceladus()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("5")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(3),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPluto()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMiranda()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(6),
                _vm._v(" "),
                _vm._m(7),
                _vm._v(" "),
                _vm._m(8),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMiranda()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIo()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(9),
                _vm._v(" "),
                _vm._m(10),
                _vm._v(" "),
                _vm._m(11),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIo()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("6")]),
                _vm._v(" "),
                _c("div", [_vm._v("8")]),
                _vm._v(" "),
                _c("div", [_vm._v("10")]),
                _vm._v(" "),
                _c("div", [_vm._v("13")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLuna()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(12),
                _vm._v(" "),
                _vm._m(13),
                _vm._v(" "),
                _vm._m(14),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLuna()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("7")]),
                _vm._v(" "),
                _c("div", [_vm._v("10")]),
                _vm._v(" "),
                _c("div", [_vm._v("13")]),
                _vm._v(" "),
                _c("div", [_vm._v("17")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(15),
                _vm._v(" "),
                _vm._m(16),
                _vm._v(" "),
                _vm._m(17),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getIapetus()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCeres()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(18),
                _vm._v(" "),
                _vm._m(19),
                _vm._v(" "),
                _vm._m(20),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCeres()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("6")]),
                _vm._v(" "),
                _c("div", [_vm._v("8")]),
                _vm._v(" "),
                _c("div", [_vm._v("10")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTriton()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(21),
                _vm._v(" "),
                _vm._m(22),
                _vm._v(" "),
                _vm._m(23),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTriton()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("5")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getGanymede()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(24),
                _vm._v(" "),
                _vm._m(25),
                _vm._v(" "),
                _vm._m(26),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getGanymede()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("5")]),
                _vm._v(" "),
                _c("div", [_vm._v("6")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCallisto()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(27),
                _vm._v(" "),
                _vm._m(28),
                _vm._v(" "),
                _vm._m(29),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getCallisto()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("5")]),
                _vm._v(" "),
                _c("div", [_vm._v("7")]),
                _vm._v(" "),
                _c("div", [_vm._v("10")]),
                _vm._v(" "),
                _c("div", [_vm._v("13")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEuropa()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(30),
                _vm._v(" "),
                _vm._m(31),
                _vm._v(" "),
                _vm._m(32),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getEuropa()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _vm._m(33),
                _vm._v(" "),
                _vm._m(34),
                _vm._v(" "),
                _vm._m(35),
                _vm._v(" "),
                _vm._m(36),
                _vm._v(" "),
                _vm._m(37),
                _vm._v(" "),
                _vm._m(38),
                _vm._v(" "),
                _vm._m(39)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPallas()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(40),
                _vm._v(" "),
                _vm._m(41),
                _vm._v(" "),
                _vm._m(42),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getPallas()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMercury()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(43),
                _vm._v(" "),
                _vm._m(44),
                _vm._v(" "),
                _vm._m(45),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getMercury()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _vm._m(46),
                _vm._v(" "),
                _vm._m(47),
                _vm._v(" "),
                _vm._m(48),
                _vm._v(" "),
                _vm._m(49),
                _vm._v(" "),
                _vm._m(50),
                _vm._v(" "),
                _vm._m(51),
                _vm._v(" "),
                _vm._m(52)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getHygiea()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(53),
                _vm._v(" "),
                _vm._m(54),
                _vm._v(" "),
                _vm._m(55),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getHygiea()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _vm._m(56),
                _vm._v(" "),
                _vm._m(57),
                _vm._v(" "),
                _vm._m(58),
                _vm._v(" "),
                _vm._m(59),
                _vm._v(" "),
                _vm._m(60),
                _vm._v(" "),
                _vm._m(61),
                _vm._v(" "),
                _vm._m(62)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(63),
                _vm._v(" "),
                _vm._m(64),
                _vm._v(" "),
                _vm._m(65),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitania()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("0")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getVenus()
            ? _c(
                "div",
                {
                  staticClass: "colony-grid-container",
                  staticStyle: { "margin-top": "5px" }
                },
                [
                  _vm._m(66),
                  _vm._v(" "),
                  _vm._m(67),
                  _vm._v(" "),
                  _vm._m(68),
                  _vm._v(" "),
                  _c("div"),
                  _vm._v(" "),
                  _c("div"),
                  _vm._v(" "),
                  _c("div"),
                  _vm._v(" "),
                  _c("div")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getVenus()
            ? _c(
                "div",
                {
                  staticClass: "colony-grid-container2",
                  staticStyle: { "margin-top": "55px" }
                },
                [
                  _c("div", [_vm._v("0")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("0")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("0")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("1")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("2")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("3")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("4")])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(69),
                _vm._v(" "),
                _vm._m(70),
                _vm._v(" "),
                _vm._m(71),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getLeavitt()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")]),
                _vm._v(" "),
                _c("div", [_vm._v("5")]),
                _vm._v(" "),
                _c("div", [_vm._v("6")]),
                _vm._v(" "),
                _c("div", [_vm._v("7")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitan()
            ? _c("div", { staticClass: "colony-grid-container" }, [
                _vm._m(72),
                _vm._v(" "),
                _vm._m(73),
                _vm._v(" "),
                _vm._m(74),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.colony.name === _vm.getTitan()
            ? _c("div", { staticClass: "colony-grid-container2" }, [
                _c("div", [_vm._v("0")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("1")]),
                _vm._v(" "),
                _c("div", [_vm._v("2")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("3")]),
                _vm._v(" "),
                _c("div", [_vm._v("4")])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus triple-res resource microbe white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus triple-res resource microbe white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus triple-res resource microbe white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource card card-with-border",
        staticStyle: {
          "margin-top": "0px",
          "margin-left": "-5px",
          transform: "scale(0.8)"
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "resource card card-with-border",
        staticStyle: { margin: "0 0 0 -30px", transform: "scale(0.8)" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource card card-with-border",
        staticStyle: {
          "margin-top": "0px",
          "margin-left": "-5px",
          transform: "scale(0.8)"
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "resource card card-with-border",
        staticStyle: { margin: "0 0 0 -30px", transform: "scale(0.8)" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource card card-with-border",
        staticStyle: {
          "margin-top": "0px",
          "margin-left": "-5px",
          transform: "scale(0.8)"
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "resource card card-with-border",
        staticStyle: { margin: "0 0 0 -30px", transform: "scale(0.8)" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource animal",
        staticStyle: { "margin-top": "11px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource animal",
        staticStyle: { "margin-top": "11px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource animal",
        staticStyle: { "margin-top": "11px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production heat" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production heat" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production heat" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production money" }, [_vm._v("2")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production money" }, [_vm._v("2")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production money" }, [_vm._v("2")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "tile rating",
        staticStyle: { transform: "scale(0.8)", "margin-left": "-1px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "tile rating",
        staticStyle: { transform: "scale(0.8)", "margin-left": "-1px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "tile rating",
        staticStyle: { transform: "scale(0.8)", "margin-left": "-1px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production steel" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production steel" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production steel" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus resource triple-res titanium white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus resource triple-res titanium white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus resource triple-res titanium white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production plant" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production plant" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production plant" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production energy" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production energy" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production energy" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tile ocean-tile ocean-tile-colony" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tile ocean-tile ocean-tile-colony" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tile ocean-tile ocean-tile-colony" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production money" }, [_vm._v("1")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production money" }, [_vm._v("1")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production energy" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production energy" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production plant" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production plant" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box" }, [
        _c("div", { staticClass: "production plant" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "influence",
        staticStyle: { "margin-top": "5px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "influence",
        staticStyle: { "margin-top": "5px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "influence",
        staticStyle: { "margin-top": "5px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "copy-trade-box" }, [_vm._v("Copy Trade")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "copy-trade-box" }, [_vm._v("Copy Trade")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "copy-trade-box" }, [_vm._v("Copy Trade")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production heat" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production heat" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production heat" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production steel" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production steel" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production titanium" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "production-box mercury-production-box" }, [
        _c("div", { staticClass: "production titanium" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource card red-outline",
        staticStyle: {
          "margin-left": "5px",
          "margin-top": "2px",
          transform: "scale(0.8)"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource card red-outline",
        staticStyle: {
          "margin-left": "5px",
          "margin-top": "2px",
          transform: "scale(0.8)"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "resource card red-outline",
        staticStyle: {
          "margin-left": "5px",
          "margin-top": "2px",
          transform: "scale(0.8)"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "resource money red-outline" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "resource money red-outline" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "resource heat red-outline" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "resource energy red-outline" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "resource plant red-outline" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "resource steel red-outline" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "resource titanium red-outline" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "points points-big",
          staticStyle: {
            transform: "scale(0.5)",
            "margin-left": "-16px",
            "margin-top": "-18px",
            height: "80px",
            "line-height": "80px",
            "font-size": "72px"
          }
        },
        [_vm._v("5")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "points points-big",
          staticStyle: {
            transform: "scale(0.5)",
            "margin-left": "-16px",
            "margin-top": "-18px",
            height: "80px",
            "line-height": "80px",
            "font-size": "72px"
          }
        },
        [_vm._v("3")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "points points-big",
          staticStyle: {
            transform: "scale(0.5)",
            "margin-left": "-16px",
            "margin-top": "-18px",
            height: "80px",
            "line-height": "80px",
            "font-size": "72px"
          }
        },
        [_vm._v("2")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tile venus-tile venus-colony-bonus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tile venus-tile venus-colony-bonus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tile venus-tile venus-colony-bonus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "tag tag-science",
        staticStyle: {
          transform: "scale(0.8)",
          "margin-top": "2px",
          "margin-left": "4px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "tag tag-science",
        staticStyle: {
          transform: "scale(0.8)",
          "margin-top": "2px",
          "margin-left": "4px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass: "tag tag-science",
        staticStyle: {
          transform: "scale(0.8)",
          "margin-top": "2px",
          "margin-left": "4px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus triple-res resource floater white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus triple-res resource floater white-x white-x--3"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticClass:
          "colony-placement-bonus triple-res resource floater white-x white-x--3"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }