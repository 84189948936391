var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "moon-tile" }),
    _vm._v(" "),
    _c("div", [
      _vm.isMax()
        ? _c("div", { staticClass: "global_params_value" }, [
            _c("img", {
              staticClass: "checkmark",
              attrs: {
                src: "/assets/misc/checkmark.png",
                alt: _vm.$t("Completed!")
              }
            })
          ])
        : _c("div", { staticClass: "moon_params_value" }, [
            _c("span", { staticClass: "colony" }, [
              _vm._v(_vm._s(_vm.colonyRate()))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "logistics" }, [
              _vm._v(_vm._s(_vm.logisticsRate()))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "mining" }, [
              _vm._v(_vm._s(_vm.miningRate()))
            ])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }