










import Vue from 'vue';
import {PlayerModel} from '../models/PlayerModel';
import {PlayerInfo} from './overview/PlayerInfo';
import {PreferencesManager} from './PreferencesManager';

export default Vue.extend({
  name: 'top-bar',
  props: {
    player: {
      type: Object as () => PlayerModel,
    },
  },
  components: {
    PlayerInfo,
  },
  data: function() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    toggleBar() {
      PreferencesManager.save('hide_top_bar', this.isExpanded(), true);
      this.forceRerender();
    },
    isExpanded(): boolean {
      return !PreferencesManager.loadBoolean('hide_top_bar');
    },
    formatCssClass(): string {
      const cssClasses = ['top-bar'];
      if ( ! this.isExpanded()) {
        cssClasses.push('top-bar-collapsed');
      }
      return cssClasses.join(' ');
    },
  },
});
