









import Vue from 'vue';
import Card from './card/Card.vue';
import {CardModel} from '../models/CardModel';

export default Vue.extend({
  name: 'stacked-cards',
  props: {
    cards: {
      type: Array as () => Array<CardModel>,
    },
  },
  components: {
    Card,
  },
});

