












import Vue from 'vue';
import {LANGUAGES} from '../constants';
import {PreferencesManager} from './PreferencesManager';

export default Vue.extend({
  name: 'language-switcher',
  data() {
    return {
      languages: LANGUAGES,
      PreferencesManager,
    };
  },
  methods: {
    switchLanguageTo(langId: string) {
      this.PreferencesManager.save('lang', langId);
      window.location.reload();
    },
  },
});
