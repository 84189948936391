var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClasses() },
    [
      _vm.requirements !== undefined
        ? _c("CardRequirementsComponent", {
            attrs: { requirements: _vm.requirements }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.renderData !== undefined
        ? _c("CardRenderData", {
            attrs: { renderData: _vm.metadata.renderData }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.description !== undefined
        ? _c("CardDescription", { attrs: { item: _vm.metadata.description } })
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.victoryPoints !== undefined
        ? _c("CardVictoryPoints", {
            attrs: { victoryPoints: _vm.metadata.victoryPoints }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }