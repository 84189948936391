













import Vue from 'vue';
import Button from '../components/common/Button.vue';
import {ColorWithNeutral} from '../Color';
import {PlayerInputModel} from '../models/PlayerInputModel';
import {PlayerModel} from '../models/PlayerModel';
import {SelectPlayerRow} from './SelectPlayerRow';
import {TranslateMixin} from './TranslateMixin';

export default Vue.extend({
  name: 'SelectPartyPlayer',
  props: {
    players: {
      type: Array as () => Array<PlayerModel>,
    },
    playerinput: {
      type: Object as () => PlayerInputModel,
    },
    onsave: {
      type: Function as unknown as () => (out: Array<Array<string>>) => void,
    },
    showsave: {
      type: Boolean,
    },
    showtitle: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      selectedPlayer: undefined as ColorWithNeutral | undefined,
    };
  },
  components: {
    Button,
    'select-player-row': SelectPlayerRow,
  },
  methods: {
    ...TranslateMixin.methods,
    saveData: function() {
      const result: string[][] = [];
      result.push([]);
      if (this.selectedPlayer !== undefined) {
        result[0].push(this.selectedPlayer);
      }
      this.onsave(result);
    },
  },
});
