var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClasses() },
    _vm._l(_vm.rows, function(rowData, index) {
      return _c(
        "div",
        { key: index, staticClass: "card-production-box-row" },
        _vm._l(rowData, function(rowItem, rowIndex) {
          return _c(
            "div",
            { key: rowIndex, staticClass: "card-production-box-row-item" },
            [
              _vm.getComponentType(rowItem) === "item"
                ? _c("CardRenderItemComponent", { attrs: { item: rowItem } })
                : _vm.getComponentType(rowItem) === "symbol"
                ? _c("CardRenderSymbolComponent", { attrs: { item: rowItem } })
                : _c("div", [_vm._v("n/a")])
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }