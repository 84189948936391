

















import Vue from 'vue';
import {TranslateMixin} from './TranslateMixin';
import {MAXIMUM_COLONY_RATE, MAXIMUM_LOGISTICS_RATE, MAXIMUM_MINING_RATE} from '../constants';
import {MoonModel} from '../models/MoonModel';

export default Vue.extend({
  name: 'MoonGlobalParameterValue',
  props: {
    moonData: {
      type: Object as () => MoonModel,
    },
  },
  methods: {
    ...TranslateMixin.methods,
    isMax: function(): boolean {
      return this.moonData.colonyRate >= MAXIMUM_COLONY_RATE &&
      this.moonData.miningRate >= MAXIMUM_MINING_RATE &&
      this.moonData.logisticsRate >= MAXIMUM_LOGISTICS_RATE;
    },
    colonyRate: function(): number {
      return this.moonData.colonyRate;
    },
    logisticsRate: function(): number {
      return this.moonData.logisticsRate;
    },
    miningRate: function(): number {
      return this.moonData.miningRate;
    },
  },
  template: `
  `,
});
