var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClasses() },
    [
      _c(
        "div",
        { staticClass: "card-effect-box-row" },
        [
          _vm.effectData.delimiter !== undefined &&
          _vm.effectData.cause !== undefined
            ? _c(
                "div",
                { staticClass: "card-effect-box-content" },
                _vm._l(_vm.effectData.cause, function(rowItem, rowIndex) {
                  return _c(
                    "div",
                    { key: rowIndex, staticClass: "card-effect-box-item" },
                    [
                      _vm.isItem(rowItem)
                        ? _c("CardRenderItemComponent", {
                            attrs: { item: rowItem }
                          })
                        : _vm.isSymbol(rowItem)
                        ? _c("CardRenderSymbolComponent", {
                            attrs: { item: rowItem }
                          })
                        : _vm.isProductionBox(rowItem)
                        ? _c("CardProductionBoxComponent", {
                            attrs: { rows: rowItem.rows }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTile(rowItem)
                        ? _c("CardRenderTileComponent", {
                            attrs: { item: rowItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.effectData.delimiter !== undefined
            ? _c("CardRenderSymbolComponent", {
                attrs: { item: _vm.effectData.delimiter }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-effect-box-content" },
            _vm._l(_vm.effectData.effect, function(rowItem, rowIndex) {
              return _c(
                "div",
                { key: rowIndex, staticClass: "card-effect-box-item" },
                [
                  _vm.isItem(rowItem)
                    ? _c("CardRenderItemComponent", {
                        attrs: { item: rowItem }
                      })
                    : _vm.isSymbol(rowItem)
                    ? _c("CardRenderSymbolComponent", {
                        attrs: { item: rowItem }
                      })
                    : _vm.isProductionBox(rowItem)
                    ? _c("CardProductionBoxComponent", {
                        attrs: { rows: rowItem.rows }
                      })
                    : _vm.isTile(rowItem)
                    ? _c("CardRenderTileComponent", {
                        attrs: { item: rowItem }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasDescription()
        ? _c("CardDescription", { attrs: { item: _vm.effectData.description } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }