var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-rows" },
    _vm._l(_vm.renderData.rows, function(cardData, i) {
      return _c("CardRowData", { key: i, attrs: { rowData: cardData } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }