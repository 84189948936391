var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: _vm.getIconClass() }),
    _vm._v(" "),
    _c("div", { staticClass: "global_params_value" }, [
      _vm.isMax()
        ? _c("div", [
            _c("img", {
              staticClass: "checkmark",
              attrs: {
                src: "/assets/misc/checkmark.png",
                alt: _vm.$t("Completed!")
              }
            })
          ])
        : _c("div", [
            _vm._v(
              "\n      " + _vm._s(_vm.value) + _vm._s(_vm.suffix()) + "\n    "
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }