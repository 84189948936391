var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "board-cont moon-board", attrs: { id: "moon_board" } },
    [
      _c(
        "svg",
        {
          staticClass: "board-legend",
          attrs: { id: "moon_board_legend", height: "550", width: "630" }
        },
        [
          _c(
            "g",
            { attrs: { id: "mare_imbrium", transform: "translate(250, 40)" } },
            [
              _c("text", { staticClass: "board-caption" }, [
                _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Mare")]),
                _vm._v(" "),
                _c("tspan", { attrs: { x: "12", dy: "12" } }, [
                  _vm._v("Imbrium")
                ])
              ]),
              _vm._v(" "),
              _c("line", {
                staticClass: "board-line",
                attrs: { x1: "24", y1: "34", x2: "33", y2: "105" }
              }),
              _vm._v(" "),
              _c(
                "text",
                {
                  staticClass: "board-caption board_caption--black",
                  attrs: { x: "30", y: "107" }
                },
                [_vm._v("●")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: { id: "mare_sereitatis", transform: "translate(485, 140)" }
            },
            [
              _c("text", { staticClass: "board-caption" }, [
                _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Mare")]),
                _vm._v(" "),
                _c("tspan", { attrs: { x: "4", dy: "12" } }, [
                  _vm._v("Serenitatis")
                ])
              ]),
              _vm._v(" "),
              _c("line", {
                staticClass: "board-line",
                attrs: { x1: "0", y1: "25", x2: "-120", y2: "50" }
              }),
              _vm._v(" "),
              _c(
                "text",
                {
                  staticClass: "board-caption board_caption--black",
                  attrs: { x: "-122", y: "53" }
                },
                [_vm._v("●")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "g",
            { attrs: { id: "mare_nubium", transform: "translate(195, 350)" } },
            [
              _c("text", { staticClass: "board-caption" }, [
                _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Mare")]),
                _vm._v(" "),
                _c("tspan", { attrs: { x: "-2", dy: "12" } }, [
                  _vm._v("Nubium")
                ])
              ]),
              _vm._v(" "),
              _c("line", {
                staticClass: "board-line",
                attrs: { x1: "29", y1: "14", x2: "115", y2: "-64" }
              }),
              _vm._v(" "),
              _c(
                "text",
                {
                  staticClass: "board-caption board_caption--black",
                  attrs: { x: "113", y: "-62" }
                },
                [_vm._v("●")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: { id: "mare_nectaris", transform: "translate(450, 300)" }
            },
            [
              _c(
                "text",
                { staticClass: "board-caption", attrs: { dx: "47" } },
                [
                  _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Mare")]),
                  _vm._v(" "),
                  _c("tspan", { attrs: { dy: "12", x: "48" } }, [
                    _vm._v("Nectaris")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("line", {
                staticClass: "board-line",
                attrs: { x1: "-39", y1: "-12", x2: "45", y2: "15" }
              }),
              _vm._v(" "),
              _c(
                "text",
                {
                  staticClass: "board-caption board_caption--black",
                  attrs: { x: "-39", y: "-9" }
                },
                [_vm._v("●")]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "board-outer-spaces" },
        [
          _c("MoonSpace", {
            attrs: {
              space: _vm.getSpaceById("m01"),
              text: "Luna Trade Station"
            }
          }),
          _vm._v(" "),
          _c("MoonSpace", {
            attrs: {
              space: _vm.getSpaceById("m37"),
              text: "Momentum Virium Habitat"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "global-numbers" }, [
        _c(
          "div",
          { staticClass: "global-numbers-colony" },
          _vm._l(_vm.getValuesForParameter("colony"), function(lvl) {
            return _c("div", { key: lvl, class: _vm.getScaleCSS(lvl) }, [
              _vm._v(_vm._s(lvl.strValue))
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "global-numbers-logistics" },
          _vm._l(_vm.getValuesForParameter("logistics"), function(lvl) {
            return _c("div", { key: lvl, class: _vm.getScaleCSS(lvl) }, [
              _vm._v(_vm._s(lvl.strValue))
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "global-numbers-mining" },
          _vm._l(_vm.getValuesForParameter("mining"), function(lvl) {
            return _c("div", { key: lvl, class: _vm.getScaleCSS(lvl) }, [
              _vm._v(_vm._s(lvl.strValue))
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "board", attrs: { id: "moon_board" } },
        _vm._l(_vm.getAllNonColonySpaces(), function(curSpace) {
          return _c("MoonSpace", {
            key: "moon-space-" + curSpace.id,
            attrs: { space: curSpace, is_selectable: true }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }