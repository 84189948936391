var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClasses() },
    [
      _c("Tag", { attrs: { tag: _vm.tag, size: _vm.size, type: _vm.type } }),
      _vm._v(" "),
      _c("span", { class: _vm.getCountClasses() }, [
        _vm._v(_vm._s(_vm.getCount()))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }