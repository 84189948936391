var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.getMainClass(),
      attrs: {
        data_space_id: _vm.space.id,
        title: _vm.getVerboseTitle(_vm.space.tileType)
      }
    },
    [
      _vm.text
        ? _c(
            "div",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              staticClass: "board-space-text"
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.space.tileType === undefined
        ? _c("bonus", { attrs: { bonus: _vm.space.bonus } })
        : _vm._e(),
      _vm._v(" "),
      _vm.space.color !== undefined
        ? _c("div", { class: "board-cube board-cube--" + _vm.space.color })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }