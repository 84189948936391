var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wf-options" },
    [
      _vm.showtitle
        ? _c("div", { staticClass: "wf-title" }, [
            _vm._v(_vm._s(_vm.$t(_vm.playerinput.title)))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.playerinput.options || [], function(option, idx) {
        return _c("player-input-factory", {
          key: idx,
          attrs: {
            players: _vm.players,
            player: _vm.player,
            playerinput: option,
            onsave: _vm.playerFactorySaved(idx),
            showsave: false,
            showtitle: true
          }
        })
      }),
      _vm._v(" "),
      _vm.showsave
        ? _c(
            "div",
            { staticClass: "wf-action" },
            [
              _c("Button", {
                attrs: {
                  title: _vm.playerinput.buttonLabel,
                  type: "submit",
                  size: "normal",
                  onClick: _vm.saveData
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }