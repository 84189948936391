var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wf-component wf-component--select-party" }, [
    _vm.showtitle === true
      ? _c("div", { staticClass: "nofloat wf-component-title" }, [
          _vm._v(_vm._s(_vm.$t(_vm.playerinput.title)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.playerinput.turmoil !== undefined
      ? _c(
          "div",
          { staticClass: "wf-component--list-party" },
          _vm._l(_vm.playerinput.turmoil.parties, function(party) {
            return _c(
              "label",
              { key: party.name },
              [
                _vm.partyAvailableToSelect(party.name)
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedParty,
                          expression: "selectedParty"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: party.name,
                        checked: _vm._q(_vm.selectedParty, party.name)
                      },
                      on: {
                        change: function($event) {
                          _vm.selectedParty = party.name
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("party", {
                  attrs: {
                    party: party,
                    isDominant: _vm.isDominant(party.name),
                    isAvailable: _vm.partyAvailableToSelect(party.name)
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showsave === true
      ? _c(
          "div",
          { staticClass: "nofloat" },
          [
            _c("Button", {
              attrs: {
                onClick: _vm.saveData,
                title: _vm.playerinput.buttonLabel
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }