var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wf-component wf-component--select-production-to-lose" },
    [
      _vm.showtitle === true
        ? _c("div", { staticClass: "nofloat wf-component-title" }, [
            _vm._v(_vm._s(_vm.$t(_vm.playerinput.title)))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h3",
        {
          directives: [{ name: "i18n", rawName: "v-i18n" }],
          staticClass: "payments_title"
        },
        [_vm._v("Which resource production would you prefer to decrease?")]
      ),
      _vm._v(" "),
      _vm.canDeductMegaCredits()
        ? _c("div", { staticClass: "payments_type input-group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("megacredits", -1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-minus" })]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.megacredits,
                  expression: "megacredits",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-input form-inline payments_input",
              domProps: { value: _vm.megacredits },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.megacredits = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("megacredits", 1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-plus" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canDeductSteel()
        ? _c("div", { staticClass: "payments_type input-group" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("steel", -1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-minus" })]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.steel,
                  expression: "steel",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-input form-inline payments_input",
              domProps: { value: _vm.steel },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.steel = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("steel", 1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-plus" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canDeductTitanium()
        ? _c("div", { staticClass: "payments_type input-group" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("titanium", -1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-minus" })]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.titanium,
                  expression: "titanium",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-input form-inline payments_input",
              domProps: { value: _vm.titanium },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.titanium = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("titanium", 1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-plus" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canDeductPlants()
        ? _c("div", { staticClass: "payments_type input-group" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("plants", -1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-minus" })]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.plants,
                  expression: "plants",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-input form-inline payments_input",
              domProps: { value: _vm.plants },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.plants = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("plants", 1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-plus" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canDeductEnergy()
        ? _c("div", { staticClass: "payments_type input-group" }, [
            _vm._m(4),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("energy", -1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-minus" })]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.energy,
                  expression: "energy",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-input form-inline payments_input",
              domProps: { value: _vm.energy },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.energy = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("energy", 1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-plus" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canDeductHeat()
        ? _c("div", { staticClass: "payments_type input-group" }, [
            _vm._m(5),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("heat", -1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-minus" })]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.heat,
                  expression: "heat",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-input form-inline payments_input",
              domProps: { value: _vm.heat },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.heat = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.delta("heat", 1)
                  }
                }
              },
              [_c("i", { staticClass: "icon icon-plus" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasWarning()
        ? _c("div", { staticClass: "tm-warning" }, [
            _c("label", { staticClass: "label label-error" }, [
              _vm._v(_vm._s(_vm.$t(_vm.warning)))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showsave === true
        ? _c("div", { staticClass: "nofloat" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-submit",
                on: { click: _vm.saveData }
              },
              [_vm._v(_vm._s(_vm.$t(_vm.playerinput.buttonLabel)))]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "production-box" }, [
      _c("div", {
        staticClass: "production resource_icon--megacredits",
        staticStyle: { "background-size": "contain" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "production-box" }, [
      _c("div", { staticClass: "production steel" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "production-box" }, [
      _c("div", { staticClass: "production titanium" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "production-box" }, [
      _c("div", { staticClass: "production plant" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "production-box" }, [
      _c("div", { staticClass: "production energy" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "production-box" }, [
      _c("div", { staticClass: "production heat" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }