









import Vue from 'vue';
import {GlobalEventModel} from '../models/TurmoilModel';

export default Vue.extend({
  name: 'global-event',
  props: {
    globalEvent: {
      type: Object as () => GlobalEventModel,
    },
    type: {
      type: String,
    },
  },
  methods: {
    partyNameClass: function(partyName: string): string {
      if (partyName === undefined) {
        return '';
      }
      return 'event-party--' + partyName.toLowerCase().split(' ').join('_');
    },
    getClass: function(): string {
      const common = 'global-event';
      switch (this.type) {
      case 'coming':
        return common + ' global-event--coming';
      case 'current':
        return common + ' global-event--current';
      default:
        return common;
      }
    },
  },
});

