







import Vue from 'vue';
import CardRowComponent from './CardRowComponent.vue';

export default Vue.extend({
  name: 'CardRowData',
  props: {
    rowData: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardRowComponent,
  },
});

