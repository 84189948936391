var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-setup-detail-container",
      attrs: { id: "game-setup-detail" }
    },
    [
      _c("ul", [
        _c("li", [
          _c(
            "div",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              staticClass: "setup-item"
            },
            [_vm._v("Expansion:")]
          ),
          _vm._v(" "),
          _vm.gameOptions.venusNextExtension
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-venus"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.preludeExtension
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-prelude"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.coloniesExtension
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-colony"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.turmoilExtension
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-turmoil"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.promoCardsOption
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-promo"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.aresExtension
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-ares"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.moonExpansion
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-themoon"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.communityCardsOption
            ? _c("div", {
                staticClass:
                  "create-game-expansion-icon expansion-icon-community"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isPoliticalAgendasOn()
            ? _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-agendas"
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "div",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              staticClass: "setup-item"
            },
            [_vm._v("Board:")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              class: _vm.getBoardColorClass(_vm.gameOptions.boardName)
            },
            [_vm._v(_vm._s(_vm.gameOptions.boardName))]
          ),
          _vm._v("\n       \n      "),
          _vm.gameOptions.shuffleMapOption
            ? _c(
                "span",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config generic"
                },
                [_vm._v("(randomized tiles)")]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "div",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              staticClass: "setup-item"
            },
            [_vm._v("WGT:")]
          ),
          _vm._v(" "),
          _vm.gameOptions.solarPhaseOption
            ? _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config generic"
                },
                [_vm._v("On")]
              )
            : _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config generic"
                },
                [_vm._v("Off")]
              )
        ]),
        _vm._v(" "),
        _vm.gameOptions.requiresVenusTrackCompletion
          ? _c("li", [_vm._v("Require Terraforming Venus to end the game")])
          : _vm._e(),
        _vm._v(" "),
        _vm.gameOptions.requiresMoonTrackCompletion
          ? _c("li", [_vm._v("Require Terraforming The Moon to end the game")])
          : _vm._e(),
        _vm._v(" "),
        _vm.playerNumber > 1
          ? _c("li", [
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "setup-item"
                },
                [_vm._v("Milestones and Awards:")]
              ),
              _vm._v(" "),
              _vm.isRandomMANone()
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("Board-defined")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRandomMALimited()
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("Randomized with limited synergy")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRandomMAUnlimited()
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("Full randomized")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRandomMANone() && _vm.gameOptions.venusNextExtension
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("HoverLord & Venuphile")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isRandomMANone() && !_vm.gameOptions.includeVenusMA
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("(5 each)")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isRandomMANone() && _vm.gameOptions.includeVenusMA
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("(6 each)")]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.playerNumber > 1
          ? _c("li", [
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "setup-item"
                },
                [_vm._v("Draft:")]
              ),
              _vm._v(" "),
              _vm.gameOptions.initialDraftVariant
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("Initial")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.gameOptions.draftVariant
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("Research phase")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.gameOptions.initialDraftVariant &&
              !_vm.gameOptions.draftVariant
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("Off")]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.gameOptions.turmoilExtension &&
        _vm.gameOptions.removeNegativeGlobalEvents
          ? _c("li", [
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "setup-item"
                },
                [_vm._v("Turmoil:")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config generic"
                },
                [_vm._v("No negative Turmoil event")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.playerNumber === 1
          ? _c("li", [
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "setup-item"
                },
                [_vm._v("Solo:")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config generic"
                },
                [_vm._v(_vm._s(this.lastSoloGeneration) + " Gens")]
              ),
              _vm._v(" "),
              _vm.gameOptions.soloTR
                ? _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("63 TR")]
                  )
                : _c(
                    "div",
                    {
                      directives: [{ name: "i18n", rawName: "v-i18n" }],
                      staticClass: "game-config generic"
                    },
                    [_vm._v("TR all")]
                  )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _c(
            "div",
            {
              directives: [{ name: "i18n", rawName: "v-i18n" }],
              staticClass: "setup-item"
            },
            [_vm._v("Game configs:")]
          ),
          _vm._v(" "),
          _vm.gameOptions.fastModeOption
            ? _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config fastmode"
                },
                [_vm._v("fast mode")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.showTimers
            ? _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config timer"
                },
                [_vm._v("timer")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.showOtherPlayersVP
            ? _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config realtime-vp"
                },
                [_vm._v("real-time vp")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.gameOptions.undoOption
            ? _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "game-config undo"
                },
                [_vm._v("undo")]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.gameOptions.cardsBlackList.length > 0
          ? _c("li", [
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "setup-item"
                },
                [_vm._v("Banned cards:")]
              ),
              _vm._v(_vm._s(_vm.gameOptions.cardsBlackList.join(", ")))
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }