










































import Vue from 'vue';
import HelpIconology from './HelpIconology.vue';
import HelpPhases from './HelpPhases.vue';
import HelpStandardProjects from './HelpStandardProjects.vue';

type Tab = 'iconology' | 'standard projects' | 'phases' | 'hotkeys';

export interface HelpPageModel {
    currentPage: Tab;
}

export default Vue.extend({
  name: 'Help',
  data: function(): HelpPageModel {
    return {
      currentPage: 'iconology',
    };
  },
  components: {
    HelpIconology,
    HelpStandardProjects,
    HelpPhases,
  },
  methods: {
    setTab: function(tab: Tab): void {
      this.currentPage = tab;
    },
    isOpen: function(tab: Tab): boolean {
      return tab === this.currentPage;
    },
  },

});
