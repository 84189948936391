


















































































































































































































































































































































































































































































































import Vue from 'vue';

import {ColonyModel} from '../models/ColonyModel';
import {ColonyName} from '../colonies/ColonyName';

export default Vue.extend({
  name: 'colony',
  props: {
    colony: {
      type: Object as () => ColonyModel,
    },
  },
  data: function() {
    return {
      PLUTO: ColonyName.PLUTO,
      GANYMEDE: ColonyName.GANYMEDE,
    };
  },
  methods: {
    getCubeXPosition: (colony: ColonyModel): number => {
      return colony.trackPosition * 56 + 27;
    },
    getColonyXPosition: (index: number): number => {
      return index * 56 + 16;
    },
    getCubeYPosition: (
      colony: ColonyModel,
      isColonyCube = false,
    ): number => {
      const offset = isColonyCube ? 7 : 0;
      switch (colony.name) {
      case ColonyName.IAPETUS:
      case ColonyName.LEAVITT:
        return 181 + offset;
      case ColonyName.VENUS:
        return 186 + offset;
      case ColonyName.PALLAS:
        return 168 + offset;
      case ColonyName.MERCURY:
      case ColonyName.HYGIEA:
        return 144 + offset;
      case ColonyName.EUROPA:
      case ColonyName.MIRANDA:
        return 166 + offset;
      case ColonyName.PLUTO:
        return 165 + offset;
      case ColonyName.LUNA:
        return 163 + offset;
      default:
        return 164 + offset;
      }
    },
    getGanymede: (): string => {
      return ColonyName.GANYMEDE;
    },
    getEuropa: (): string => {
      return ColonyName.EUROPA;
    },
    getCeres: (): string => {
      return ColonyName.CERES;
    },
    getPluto: (): string => {
      return ColonyName.PLUTO;
    },
    getEnceladus: (): string => {
      return ColonyName.ENCELADUS;
    },
    getIo: (): string => {
      return ColonyName.IO;
    },
    getTriton: (): string => {
      return ColonyName.TRITON;
    },
    getTitan: (): string => {
      return ColonyName.TITAN;
    },
    getLuna: (): string => {
      return ColonyName.LUNA;
    },
    getMiranda: (): string => {
      return ColonyName.MIRANDA;
    },
    getCallisto: (): string => {
      return ColonyName.CALLISTO;
    },
    getColonyContentOffset: (colony: ColonyModel): number => {
      if (colony.name === ColonyName.PLUTO || colony.name === ColonyName.MIRANDA) return -12;
      return 0;
    },
    getIapetus: (): string => {
      return ColonyName.IAPETUS;
    },
    getMercury: (): string => {
      return ColonyName.MERCURY;
    },
    getHygiea: (): string => {
      return ColonyName.HYGIEA;
    },
    getTitania: (): string => {
      return ColonyName.TITANIA;
    },
    getVenus: (): string => {
      return ColonyName.VENUS;
    },
    getLeavitt: (): string => {
      return ColonyName.LEAVITT;
    },
    getPallas: (): string => {
      return ColonyName.PALLAS;
    },
  },
});

