var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.waitingfor === undefined
    ? _c("div", [_vm._v(_vm._s(_vm.$t("Not your turn to take any actions")))])
    : _c(
        "div",
        { staticClass: "wf-root" },
        [
          _c("player-input-factory", {
            attrs: {
              players: _vm.players,
              player: _vm.player,
              playerinput: _vm.waitingfor,
              onsave: _vm.onsave,
              showsave: true,
              showtitle: true
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }