var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "player-tags" }, [
    _c(
      "div",
      { staticClass: "player-tags-main" },
      [
        _c("tag-count", {
          attrs: {
            tag: "vp",
            count: _vm.getVpCount(),
            size: "big",
            type: "main",
            hideCount: _vm.hideVpCount()
          }
        }),
        _vm._v(" "),
        _c("tag-count", {
          attrs: { tag: "tr", count: _vm.getTR(), size: "big", type: "main" }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tag-and-discount" },
          [
            _vm.hasTagDiscount("all")
              ? _c("PlayerTagDiscount", {
                  attrs: {
                    amount: _vm.getTagDiscountAmount("all"),
                    color: _vm.player.color
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("tag-count", {
              attrs: {
                tag: "cards",
                count: _vm.getCardCount(),
                size: "big",
                type: "main"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "player-tags-secondary" },
      [
        _vm.showShortTags()
          ? _vm._l(_vm.player.tags, function(tag) {
              return _c(
                "div",
                { key: tag.tag, staticClass: "tag-count-container" },
                [
                  _c(
                    "div",
                    { staticClass: "tag-and-discount" },
                    [
                      _vm.hasTagDiscount(tag.tag)
                        ? _c("PlayerTagDiscount", {
                            attrs: {
                              amount: _vm.getTagDiscountAmount(tag.tag),
                              color: _vm.player.color
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showJovianMultipliers(tag.tag)
                        ? _c("JovianMultiplier", {
                            attrs: {
                              amount: _vm.playerJovianMultipliersCount()
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("tag-count", {
                        attrs: {
                          tag: tag.tag,
                          count: tag.count,
                          size: "big",
                          type: "secondary"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            })
          : _vm._l(_vm.getTagsPlaceholders(), function(tagName) {
              return _c(
                "div",
                { key: tagName, staticClass: "tag-count-container" },
                [
                  tagName !== "separator"
                    ? _c(
                        "div",
                        { staticClass: "tag-and-discount" },
                        [
                          _vm.hasTagDiscount(tagName)
                            ? _c("PlayerTagDiscount", {
                                attrs: {
                                  color: _vm.player.color,
                                  amount: _vm.getTagDiscountAmount(tagName)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showJovianMultipliers(tagName)
                            ? _c("JovianMultiplier", {
                                attrs: {
                                  amount: _vm.playerJovianMultipliersCount()
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("tag-count", {
                            attrs: {
                              tag: tagName,
                              count: _vm.getTagCount(tagName),
                              size: "big",
                              type: "secondary"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", { staticClass: "tag-separator" })
                ]
              )
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }