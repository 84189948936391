var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "players-overview-settings" }, [
    _c(
      "div",
      {
        staticClass: "setting-button",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggleTagsView()
          }
        }
      },
      [_vm._v(_vm._s(_vm.getTagToggleLabel()))]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "setting-label" }, [
      _vm._v("[ toggle tags view ]")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }