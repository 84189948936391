var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.getGameBoardClassName() }, [
    _c("div", { staticClass: "hide-tile-button-container" }, [
      _c(
        "div",
        {
          staticClass: "hide-tile-button",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleHideTile()
            }
          }
        },
        [_vm._v(_vm._s(_vm.toggleHideTileLabel()))]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "board-outer-spaces" },
      [
        _c("board-space", {
          attrs: { space: _vm.getSpaceById("01"), text: "Ganymede Colony" }
        }),
        _vm._v(" "),
        _c("board-space", {
          attrs: { space: _vm.getSpaceById("02"), text: "Phobos Space Haven" }
        }),
        _vm._v(" "),
        _c("board-space", {
          attrs: { space: _vm.getSpaceById("69"), text: "Stanford Torus" }
        }),
        _vm._v(" "),
        _vm.venusNextExtension
          ? _c("board-space", {
              attrs: { space: _vm.getSpaceById("70"), text: "Luna Metropolis" }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.venusNextExtension
          ? _c("board-space", {
              attrs: { space: _vm.getSpaceById("71"), text: "Dawn City" }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.venusNextExtension
          ? _c("board-space", {
              attrs: { space: _vm.getSpaceById("72"), text: "Stratopolis" }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.venusNextExtension
          ? _c("board-space", {
              attrs: { space: _vm.getSpaceById("73"), text: "Maxwell Base" }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "global-numbers" }, [
      _c(
        "div",
        { staticClass: "global-numbers-temperature" },
        _vm._l(_vm.getValuesForParameter("temperature"), function(lvl, idx) {
          return _c("div", { key: idx, class: _vm.getScaleCSS(lvl) }, [
            _vm._v(_vm._s(lvl.strValue))
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "global-numbers-oxygen" },
        _vm._l(_vm.getValuesForParameter("oxygen"), function(lvl, idx) {
          return _c("div", { key: idx, class: _vm.getScaleCSS(lvl) }, [
            _vm._v(_vm._s(lvl.strValue))
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.venusNextExtension
        ? _c(
            "div",
            { staticClass: "global-numbers-venus" },
            _vm._l(_vm.getValuesForParameter("venus"), function(lvl, idx) {
              return _c("div", { key: idx, class: _vm.getScaleCSS(lvl) }, [
                _vm._v(_vm._s(lvl.strValue))
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "global-numbers-oceans" }, [
        this.oceans_count === this.constants.MAX_OCEAN_TILES
          ? _c("span", [
              _c("img", {
                staticClass: "board-ocean-checkmark",
                attrs: {
                  width: "26",
                  src: "/assets/misc/circle-checkmark.png",
                  alt: _vm.$t("Completed!")
                }
              })
            ])
          : _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(this.oceans_count) +
                  "/" +
                  _vm._s(this.constants.MAX_OCEAN_TILES) +
                  "\n          "
              )
            ])
      ]),
      _vm._v(" "),
      _vm.aresExtension && _vm.aresData !== undefined
        ? _c("div", [
            _vm.aresData.hazardData.erosionOceanCount.available
              ? _c("div", [
                  _c("div", { staticClass: "global-ares-erosions-icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "global-ares-erosions-val" }, [
                    _vm._v(
                      _vm._s(
                        _vm.aresData.hazardData.erosionOceanCount.threshold
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.aresData.hazardData.removeDustStormsOceanCount.available
              ? _c("div", [
                  _c("div", {
                    staticClass: "global-ares-remove-dust-storms-icon"
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "global-ares-remove-dust-storms-val" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.aresData.hazardData.removeDustStormsOceanCount
                            .threshold
                        )
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.aresData.hazardData.severeErosionTemperature.available
              ? _c("div", [
                  _c("div", {
                    staticClass: "global-ares-severe-erosions",
                    class:
                      "global-ares-severe-erosions-" +
                      _vm.aresData.hazardData.severeErosionTemperature.threshold
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.aresData.hazardData.severeDustStormOxygen.available
              ? _c("div", [
                  _c("div", {
                    staticClass: "global-ares-severe-dust-storms",
                    class:
                      "global-ares-severe-dust-storms-" +
                      _vm.aresData.hazardData.severeDustStormOxygen.threshold
                  })
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "board", attrs: { id: "main_board" } },
      [
        _vm._l(_vm.getAllSpacesOnMars(), function(curSpace) {
          return _c("board-space", {
            key: "board-space-" + curSpace.id,
            attrs: {
              space: curSpace,
              is_selectable: true,
              aresExtension: _vm.aresExtension,
              isTileHidden: _vm.checkHideTile()
            }
          })
        }),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "board-legend",
            attrs: { id: "board_legend", height: "550", width: "630" }
          },
          [
            _vm.boardName === "tharsis"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "ascraeus_mons",
                      transform: "translate(95, 192)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [
                        _vm._v("Ascraeus")
                      ]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "12", dy: "12" } }, [
                        _vm._v("Mons")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("line", {
                      staticClass: "board-line",
                      attrs: { x1: "38", y1: "20", x2: "88", y2: "26" }
                    }),
                    _vm._v(" "),
                    _c(
                      "text",
                      {
                        staticClass: "board-caption board_caption--black",
                        attrs: { x: "86", y: "29" }
                      },
                      [_vm._v("●")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "tharsis"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "pavonis_mons",
                      transform: "translate(90, 230)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Pavonis")]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "4", dy: "12" } }, [
                        _vm._v("Mons")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("line", {
                      staticClass: "board-line",
                      attrs: { x1: "35", y1: "25", x2: "72", y2: "30" }
                    }),
                    _vm._v(" "),
                    _c(
                      "text",
                      {
                        staticClass: "board-caption board_caption--black",
                        attrs: { x: "66", y: "33" }
                      },
                      [_vm._v("●")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "tharsis"
              ? _c(
                  "g",
                  {
                    attrs: { id: "arsia_mons", transform: "translate(77, 275)" }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Arsia")]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "-2", dy: "12" } }, [
                        _vm._v("Mons")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("line", {
                      staticClass: "board-line",
                      attrs: { x1: "25", y1: "20", x2: "49", y2: "26" }
                    }),
                    _vm._v(" "),
                    _c(
                      "text",
                      {
                        staticClass: "board-caption board_caption--black",
                        attrs: { x: "47", y: "29" }
                      },
                      [_vm._v("●")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "tharsis"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "tharsis_tholus",
                      transform: "translate(85, 175)"
                    }
                  },
                  [
                    _c(
                      "text",
                      { staticClass: "board-caption", attrs: { dx: "47" } },
                      [
                        _c("tspan", { attrs: { dy: "-7" } }, [
                          _vm._v("Tharsis")
                        ]),
                        _vm._v(" "),
                        _c("tspan", { attrs: { dy: "12", x: "48" } }, [
                          _vm._v("Tholus")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("line", {
                      staticClass: "board-line",
                      attrs: { y1: "-3", x2: "160", y2: "2", x1: "90" }
                    }),
                    _vm._v(" "),
                    _c(
                      "text",
                      {
                        staticClass: "board-caption board_caption--black",
                        attrs: { x: "158", y: "5" }
                      },
                      [_vm._v("●")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "tharsis"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "noctis_city",
                      transform: "translate(85, 320)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Noctis")]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "7", dy: "12" } }, [
                        _vm._v("City")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("line", {
                      staticClass: "board-line",
                      attrs: { x1: "30", y1: "20", x2: "140", y2: "-20" }
                    }),
                    _vm._v(" "),
                    _c(
                      "text",
                      {
                        staticClass: "board-caption board_caption--black",
                        attrs: { x: "136", y: "-18" }
                      },
                      [_vm._v("●")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "elysium"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "elysium_mons",
                      transform: "translate(110, 190)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Elysium")]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "8", dy: "12" } }, [
                        _vm._v("Mons")
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "elysium"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "hecatus_tholus",
                      transform: "translate(130, 150)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Hecatus")]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "3", dy: "12" } }, [
                        _vm._v("Tholus")
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "elysium"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "arsia_mons",
                      transform: "translate(545, 272)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { dy: "15" } }, [_vm._v("Arsia")]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "0", dy: "12" } }, [
                        _vm._v("Mons")
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.boardName === "elysium"
              ? _c(
                  "g",
                  {
                    attrs: {
                      id: "olympus_mons",
                      transform: "translate(505, 190)"
                    }
                  },
                  [
                    _c("text", { staticClass: "board-caption" }, [
                      _c("tspan", { attrs: { x: "-5", dy: "15" } }, [
                        _vm._v("Olympus")
                      ]),
                      _vm._v(" "),
                      _c("tspan", { attrs: { x: "4", dy: "12" } }, [
                        _vm._v("Mons")
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }