


























import Vue from 'vue';
import LanguageSwitcher from './LanguageSwitcher.vue';

import * as raw_settings from '../genfiles/settings.json';

export default Vue.extend({
  name: 'start-screen',
  props: {
    version: {
      type: String as () => typeof raw_settings.version,
    },
  },
  components: {
    LanguageSwitcher,
  },
  methods: {
    getAppVersion(): string {
      const versionParts = this.version.split(' ');
      return versionParts[0];
    },
    getAppDate(): string {
      const versionParts = this.version.split(' ');
      if (versionParts.length > 1) {
        return versionParts.slice(1).join(' ');
      }
      return '';
    },
  },
});

