var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "debug-ui-container", class: _vm.getLanguageCssClass() },
    [
      _c("h1", [_vm._v("Cards List")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterText,
              expression: "filterText"
            }
          ],
          staticClass: "form-input form-input-line",
          attrs: { placeholder: "filter" },
          domProps: { value: _vm.filterText },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.filterText = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterDescription,
              expression: "filterDescription"
            }
          ],
          attrs: {
            type: "checkbox",
            name: "filterDescription",
            id: "filterDescription-checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.filterDescription)
              ? _vm._i(_vm.filterDescription, null) > -1
              : _vm.filterDescription
          },
          on: {
            change: function($event) {
              var $$a = _vm.filterDescription,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.filterDescription = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.filterDescription = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.filterDescription = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "filterDescription-checkbox" } }, [
          _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Filter description")
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sortById,
              expression: "sortById"
            }
          ],
          attrs: {
            type: "checkbox",
            name: "sortById",
            id: "sortById-checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.sortById)
              ? _vm._i(_vm.sortById, null) > -1
              : _vm.sortById
          },
          on: {
            change: function($event) {
              var $$a = _vm.sortById,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.sortById = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.sortById = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.sortById = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "sortById-checkbox" } }, [
          _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Sort by ID (work in progress)")
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "create-game-page-column",
          staticStyle: { "flex-flow": "inherit" }
        },
        [
          _c(
            "button",
            {
              attrs: { id: "toggle-checkbox" },
              on: {
                click: function($event) {
                  return _vm.toggleAll()
                }
              }
            },
            [
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Toggle all")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.base,
                expression: "base"
              }
            ],
            attrs: { type: "checkbox", name: "base", id: "base-checkbox" },
            domProps: {
              checked: Array.isArray(_vm.base)
                ? _vm._i(_vm.base, null) > -1
                : _vm.base
            },
            on: {
              change: function($event) {
                var $$a = _vm.base,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.base = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.base = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.base = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "base-checkbox" }
            },
            [
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Base")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.corporateEra,
                expression: "corporateEra"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "corporateEra",
              id: "corporateEra-checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.corporateEra)
                ? _vm._i(_vm.corporateEra, null) > -1
                : _vm.corporateEra
            },
            on: {
              change: function($event) {
                var $$a = _vm.corporateEra,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.corporateEra = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.corporateEra = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.corporateEra = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "corporateEra-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-CE"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Corporate Era")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.prelude,
                expression: "prelude"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "prelude",
              id: "prelude-checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.prelude)
                ? _vm._i(_vm.prelude, null) > -1
                : _vm.prelude
            },
            on: {
              change: function($event) {
                var $$a = _vm.prelude,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.prelude = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.prelude = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.prelude = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "prelude-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-prelude"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Prelude")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.venusNext,
                expression: "venusNext"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "venusNext",
              id: "venusNext-checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.venusNext)
                ? _vm._i(_vm.venusNext, null) > -1
                : _vm.venusNext
            },
            on: {
              change: function($event) {
                var $$a = _vm.venusNext,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.venusNext = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.venusNext = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.venusNext = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "venusNext-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-venus"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Venus Next")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colonies,
                expression: "colonies"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "colonies",
              id: "colonies-checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.colonies)
                ? _vm._i(_vm.colonies, null) > -1
                : _vm.colonies
            },
            on: {
              change: function($event) {
                var $$a = _vm.colonies,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.colonies = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.colonies = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.colonies = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "colonies-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-colony"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Colonies")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.turmoil,
                expression: "turmoil"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "turmoil",
              id: "turmoil-checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.turmoil)
                ? _vm._i(_vm.turmoil, null) > -1
                : _vm.turmoil
            },
            on: {
              change: function($event) {
                var $$a = _vm.turmoil,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.turmoil = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.turmoil = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.turmoil = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "turmoil-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-turmoil"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Turmoil")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.promo,
                expression: "promo"
              }
            ],
            attrs: { type: "checkbox", name: "promo", id: "promo-checkbox" },
            domProps: {
              checked: Array.isArray(_vm.promo)
                ? _vm._i(_vm.promo, null) > -1
                : _vm.promo
            },
            on: {
              change: function($event) {
                var $$a = _vm.promo,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.promo = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.promo = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.promo = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "promo-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-promo"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Promos")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.ares,
                expression: "ares"
              }
            ],
            attrs: { type: "checkbox", name: "ares", id: "ares-checkbox" },
            domProps: {
              checked: Array.isArray(_vm.ares)
                ? _vm._i(_vm.ares, null) > -1
                : _vm.ares
            },
            on: {
              change: function($event) {
                var $$a = _vm.ares,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.ares = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.ares = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.ares = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "ares-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-ares"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Ares")]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.community,
                expression: "community"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "community",
              id: "community-checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.community)
                ? _vm._i(_vm.community, null) > -1
                : _vm.community
            },
            on: {
              change: function($event) {
                var $$a = _vm.community,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.community = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.community = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.community = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "community-checkbox" }
            },
            [
              _c("div", {
                staticClass:
                  "create-game-expansion-icon expansion-icon-community"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Community")]
              )
            ]
          ),
          _c("span"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.moon,
                expression: "moon"
              }
            ],
            attrs: { type: "checkbox", name: "moon", id: "moon-checkbox" },
            domProps: {
              checked: Array.isArray(_vm.moon)
                ? _vm._i(_vm.moon, null) > -1
                : _vm.moon
            },
            on: {
              change: function($event) {
                var $$a = _vm.moon,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.moon = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.moon = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.moon = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "expansion-button",
              attrs: { for: "moon-checkbox" }
            },
            [
              _c("div", {
                staticClass: "create-game-expansion-icon expansion-icon-themoon"
              }),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("The Moon")]
              )
            ]
          ),
          _c("span")
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "debug-ui-cards-list" },
        [
          _c("h2", [_vm._v("Project Cards")]),
          _vm._v(" "),
          _vm._l(_vm.getAllProjectCards(), function(card) {
            return _c(
              "div",
              { key: card, staticClass: "cardbox" },
              [
                _c("Card", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filtered(card),
                      expression: "filtered(card)"
                    }
                  ],
                  attrs: { card: { name: card } }
                })
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "debug-ui-cards-list" },
        [
          _c("h2", [_vm._v("Corporations")]),
          _vm._v(" "),
          _vm._l(_vm.getAllCorporationCards(), function(card) {
            return _c(
              "div",
              { key: card, staticClass: "cardbox" },
              [
                _c("Card", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filtered(card),
                      expression: "filtered(card)"
                    }
                  ],
                  attrs: { card: { name: card } }
                })
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "debug-ui-cards-list" },
        [
          _c("h2", [_vm._v("Preludes")]),
          _vm._v(" "),
          _vm._l(_vm.getAllPreludeCards(), function(card) {
            return _c(
              "div",
              { key: card, staticClass: "cardbox" },
              [
                _c("Card", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filtered(card),
                      expression: "filtered(card)"
                    }
                  ],
                  attrs: { card: { name: card } }
                })
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "debug-ui-cards-list" },
        [
          _c("h2", [_vm._v("Standard Projects")]),
          _vm._v(" "),
          _vm._l(_vm.getAllStandardProjectCards(), function(card) {
            return _c(
              "div",
              { key: card, staticClass: "cardbox" },
              [
                _c("Card", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.filtered(card),
                      expression: "filtered(card)"
                    }
                  ],
                  attrs: { card: { name: card } }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legacy-anchor" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://ssimeonoff.github.io/cards-list",
            target: "_blank"
          }
        },
        [_vm._v("legacy card UI")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }