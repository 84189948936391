


















import Vue from 'vue';
import {PlayerModel} from '../models/PlayerModel';
import {PlayerInputModel} from '../models/PlayerInputModel';
import Button from '../components/common/Button.vue';
import {TranslateMixin} from '../components/TranslateMixin';

export default Vue.extend({
  name: 'and-options',
  props: {
    player: {
      type: Object as () => PlayerModel,
    },
    players: {
      type: Array as () => Array<PlayerModel>,
    },
    playerinput: {
      type: Object as () => PlayerInputModel,
    },
    onsave: {
      type: Function as unknown as () => (out: Array<Array<string>>) => void,
    },
    showsave: {
      type: Boolean,
    },
    showtitle: {
      type: Boolean,
    },
  },
  components: {
    Button,
  },
  data: function() {
    if (this.playerinput.options === undefined) {
      throw new Error('options must be defined');
    }
    return {
      responded: this.playerinput.options.map(() => undefined),
    };
  },
  methods: {
    ...TranslateMixin.methods,
    playerFactorySaved: function(idx: number) {
      return (out: Array<Array<string>>) => {
        this.$data.responded[idx] = out[0];
      };
    },
    saveData: function() {
      for (const child of this.$children) {
        if ((child as any).saveData instanceof Function) {
          (child as any).saveData();
        }
      }
      this.onsave(this.$data.responded);
    },
  },
});

