







import Vue from 'vue';
import CardTag from './CardTag.vue';
import {Tags} from '../../cards/Tags';

export default Vue.extend({
  name: 'CardTags',
  props: {
    tags: Array as () => Array<Tags>,
  },
  components: {
    CardTag,
  },
});

