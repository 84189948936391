var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClasses() },
    [
      _c("div", { staticClass: "card-corporation-label" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _vm._l(_vm.rows[0], function(rowData, index) {
        return _c(
          "div",
          { key: index },
          [
            _vm.getComponentType(rowData) === "item"
              ? _c("CardRenderItemComponent", { attrs: { item: rowData } })
              : _vm.getComponentType(rowData) === "symbol"
              ? _c("CardRenderSymbolComponent", { attrs: { item: rowData } })
              : _vm._e(),
            _vm._v(" "),
            _vm.getComponentType(rowData) === "effect"
              ? _c("CardRenderEffectBoxComponent", {
                  attrs: { effectData: rowData }
                })
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }