
export enum SpaceName {
    ARSIA_MONS = '29',
    ASCRAEUS_MONS = '14',
    GANYMEDE_COLONY = '01',
    NOCTIS_CITY = '31',
    PAVONIS_MONS = '21',
    PHOBOS_SPACE_HAVEN = '02',
    THARSIS_THOLUS = '09',
    LUNA_METROPOLIS = '70',
    DAWN_CITY = '71',
    STRATOPOLIS = '72',
    MAXWELL_BASE = '73',
    ARSIA_MONS_ELYSIUM = '37',
    HECATES_THOLUS = '08',
    ELYSIUM_MONS = '14',
    OLYMPUS_MONS = '20',
    HELLAS_OCEAN_TILE = '61',
    STANFORD_TORUS = '69'
}
