var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-item-container" },
    [
      _vm.item.showDigit
        ? _c("div", { staticClass: "card-res-amount" }, [
            _vm._v(_vm._s(_vm.getAmountAbs()))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.itemsToShow(), function(index) {
        return _c("div", {
          key: index,
          class: _vm.getComponentClasses(),
          domProps: { innerHTML: _vm._s(_vm.itemHtmlContent()) }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }