var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-home-container", attrs: { id: "game-home" } },
    [
      _c("h1", [
        _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
          _vm._v("Terraforming mars")
        ]),
        _vm._v(" [game id: "),
        _c("span", [_vm._v(_vm._s(_vm.getGameId()))]),
        _vm._v("]")
      ]),
      _vm._v(" "),
      _c("h4", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
        _vm._v(
          "Instructions: To start the game, separately copy and share the links with all players, and then click on your name. "
        ),
        _c("br"),
        _vm._v(
          "Save this page in case you or one of your opponents loses a link."
        )
      ]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.game === undefined ? [] : _vm.game.players, function(
          player,
          index
        ) {
          return _c(
            "li",
            { key: player.color },
            [
              _c("span", { staticClass: "turn-order" }, [
                _vm._v(_vm._s(_vm.getTurnOrder(index)))
              ]),
              _vm._v(" "),
              _c("span", {
                class:
                  "color-square " + _vm.getPlayerCubeColorClass(player.color)
              }),
              _vm._v(" "),
              _c("span", { staticClass: "player-name" }, [
                _c("a", { attrs: { href: _vm.getHref(player.id) } }, [
                  _vm._v(_vm._s(player.name))
                ])
              ]),
              _vm._v(" "),
              _c("Button", {
                attrs: {
                  title: "copy",
                  size: "tiny",
                  onClick: function(_) {
                    return _vm.copyUrl(player.id)
                  }
                }
              }),
              _vm._v(" "),
              _vm.isPlayerUrlCopied(player.id)
                ? _c("span", { staticClass: "copied-notice" }, [
                    _vm._v(
                      "Playable link for " +
                        _vm._s(player.name) +
                        " copied to clipboard "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dismissed",
                        on: { click: _vm.setCopiedIdToDefault }
                      },
                      [_vm._v("dismiss")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "spacing-setup" }),
      _vm._v(" "),
      _vm.game !== undefined
        ? _c(
            "div",
            [
              _c("h1", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Game settings")
              ]),
              _vm._v(" "),
              _c("game-setup-detail", {
                attrs: {
                  gameOptions: _vm.game.gameOptions,
                  playerNumber: _vm.game.players.length,
                  lastSoloGeneration: _vm.game.lastSoloGeneration
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }