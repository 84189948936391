


























import Vue from 'vue';
import CardRenderItemComponent from './CardRenderItemComponent.vue';
import CardRenderSymbolComponent from './CardRenderSymbolComponent.vue';
import {ItemType, CardRenderEffect, CardRenderProductionBox, CardRenderTile} from '../../cards/render/CardRenderer';
import CardProductionBoxComponent from './CardProductionBoxComponent.vue';
import CardRenderTileComponent from './CardRenderTileComponent.vue';
import {CardRenderSymbol} from '../../cards/render/CardRenderSymbol';
import {CardRenderItem} from '../../cards/render/CardRenderItem';

import CardDescription from './CardDescription.vue';

export default Vue.extend({
  name: 'CardRenderEffectBoxComponent',
  props: {
    effectData: {
      type: Object as () => CardRenderEffect,
      required: true,
    },
  },
  components: {
    CardRenderItemComponent,
    CardRenderSymbolComponent,
    CardProductionBoxComponent,
    CardRenderTileComponent,
    CardDescription,
  },
  methods: {
    getClasses: function(): string {
      return 'card-effect-box';
    },
    isItem: function(rowItem: ItemType): rowItem is CardRenderItem {
      return rowItem instanceof CardRenderItem;
    },
    isSymbol: function(rowItem: ItemType): rowItem is CardRenderSymbol {
      return rowItem instanceof CardRenderSymbol;
    },
    isProductionBox: function(rowItem: ItemType): rowItem is CardRenderProductionBox {
      return rowItem instanceof CardRenderProductionBox;
    },
    isTile: function(rowItem: ItemType): rowItem is CardRenderTile {
      return rowItem instanceof CardRenderTile;
    },
    hasDescription: function(): boolean {
      return this.effectData.description !== undefined;
    },
  },
});

