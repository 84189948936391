var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showtitle === true
        ? _c("div", [_vm._v(_vm._s(_vm.$t(_vm.playerinput.title)))])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.playerinput.players || [], function(player) {
        return _c(
          "label",
          { key: player, staticClass: "form-radio form-inline" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedPlayer,
                  expression: "selectedPlayer"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: player,
                checked: _vm._q(_vm.selectedPlayer, player)
              },
              on: {
                change: function($event) {
                  _vm.selectedPlayer = player
                }
              }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "form-icon" }),
            _vm._v(" "),
            player === "NEUTRAL"
              ? _c("span", [_vm._v("Neutral")])
              : _c("select-player-row", {
                  attrs: {
                    player: _vm.players.find(function(otherPlayer) {
                      return otherPlayer.color === player
                    })
                  }
                })
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.showsave === true
        ? _c("Button", {
            attrs: {
              size: "big",
              onClick: _vm.saveData,
              title: _vm.$t(_vm.playerinput.buttonLabel)
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }