







































































import Vue from 'vue';
import {GameOptionsModel} from '../models/GameOptionsModel';
import {BoardName} from '../boards/BoardName';
import {RandomMAOptionType} from '../RandomMAOptionType';
import {AgendaStyle} from '../turmoil/PoliticalAgendas';

export default Vue.extend({
  name: 'game-setup-detail',
  props: {
    playerNumber: {
      type: Number,
    },
    gameOptions: {
      type: Object as () => GameOptionsModel,
    },
    lastSoloGeneration: {
      type: Number,
    },
  },
  methods: {
    isPoliticalAgendasOn: function(): boolean {
      return (this.gameOptions.politicalAgendasExtension !== AgendaStyle.STANDARD);
    },
    getBoardColorClass: function(boardName: string): string {
      if (boardName === BoardName.ORIGINAL) {
        return 'game-config board-tharsis map';
      } else if (boardName === BoardName.HELLAS) {
        return 'game-config board-hellas map';
      } else if (boardName === BoardName.ELYSIUM) {
        return 'game-config board-elysium map';
      } else {
        return 'game-config board-other map';
      }
    },
    isRandomMANone: function(): boolean {
      return this.gameOptions.randomMA === RandomMAOptionType.NONE;
    },
    isRandomMALimited: function(): boolean {
      return this.gameOptions.randomMA === RandomMAOptionType.LIMITED;
    },
    isRandomMAUnlimited: function(): boolean {
      return this.gameOptions.randomMA === RandomMAOptionType.UNLIMITED;
    },
  },
});

