var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [{ name: "i18n", rawName: "v-i18n" }],
      class: _vm.getOuterClass(),
      attrs: { disabled: _vm.getDisabled() },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onClick($event)
        }
      }
    },
    [
      _vm.getInnerClass() !== ""
        ? _c("span", { class: _vm.getInnerClass() })
        : _c("span", [_vm._v(_vm._s(this.title))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }