


















































































































































import Vue from 'vue';
import {PlayerModel, PublicPlayerModel} from '../models/PlayerModel';
import Board from './Board.vue';
import MoonBoard from './moon/MoonBoard.vue';
import LogPanel from './LogPanel.vue';
import Button from '../components/common/Button.vue';
import {playerColorClass} from '../utils/utils';
import {Timer} from '../Timer';

import * as constants from '../constants';

export default Vue.extend({
  name: 'game-end',
  props: {
    player: {
      type: Object as () => PlayerModel,
    },
  },
  data: function() {
    return {
      constants,
    };
  },
  components: {
    'board': Board,
    'log-panel': LogPanel,
    Button,
    MoonBoard,
  },
  methods: {
    getEndGamePlayerRowColorClass: function(color: string): string {
      return playerColorClass(color.toLowerCase(), 'bg_transparent');
    },
    getTimer: function(p: PublicPlayerModel): string {
      return Timer.toString(p.timer);
    },
    getSortedPlayers: function() {
      this.player.players.sort(function(a:PublicPlayerModel, b:PublicPlayerModel) {
        if (a.victoryPointsBreakdown.total < b.victoryPointsBreakdown.total) return -1;
        if (a.victoryPointsBreakdown.total > b.victoryPointsBreakdown.total) return 1;
        if (a.megaCredits < b.megaCredits) return -1;
        if (a.megaCredits > b.megaCredits) return 1;
        return 0;
      });
      return this.player.players.reverse();
    },
    getWinners: function() {
      const sortedPlayers = this.getSortedPlayers();
      const firstWinner = sortedPlayers[0];
      const winners: PublicPlayerModel[] = [firstWinner];
      for (let i = 1; i < sortedPlayers.length; i++) {
        if (sortedPlayers[i].victoryPointsBreakdown.total === firstWinner.victoryPointsBreakdown.total &&
                    sortedPlayers[i].megaCredits === firstWinner.megaCredits) {
          winners.push(sortedPlayers[i]);
        }
      }
      return winners;
    },
    isSoloGame: function(): boolean {
      return this.player.players.length === 1;
    },
  },
});

