











































import Vue from 'vue';
import {IAresGlobalParametersResponse} from '../inputs/ShiftAresGlobalParameters';
import {PlayerInputModel} from '../models/PlayerInputModel';

export default Vue.extend({
  name: 'ShiftAresGlobalParameters',
  props: {
    playerinput: {
      type: Object as () => Required<Pick<PlayerInputModel, 'aresData' | 'buttonLabel'>>,
    },
    onsave: {
      type: Function as unknown as () => (out: Array<Array<string>>) => void,
    },
    showsave: {
      type: Boolean,
    },
    showtitle: {
      type: Boolean,
    },
  },
  data: function() {
    const hazardData = this.playerinput.aresData.hazardData;
    return {
      hazardData: hazardData,
      lowOceanDelta: 0,
      highOceanDelta: 0,
      temperatureDelta: 0,
      oxygenDelta: 0,
      ADJUSTMENT_RANGE: [-1, 0, 1],
    };
  },
  methods: {
    saveData: function() {
      const response: IAresGlobalParametersResponse = {
        lowOceanDelta: this.$data.lowOceanDelta,
        highOceanDelta: this.$data.highOceanDelta,
        temperatureDelta: this.$data.temperatureDelta,
        oxygenDelta: this.$data.oxygenDelta,
      };

      this.onsave([[
        JSON.stringify(response),
      ]]);
    },
  },
});
