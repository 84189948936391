var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "trim-whitespace", rawName: "v-trim-whitespace" }],
      staticClass: "milestones_cont"
    },
    [
      _c("div", { staticClass: "milestones" }, [
        _c(
          "div",
          { staticClass: "ma-title" },
          [
            _c(
              "a",
              {
                directives: [{ name: "i18n", rawName: "v-i18n" }],
                staticClass: "ma-clickable",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleList()
                  }
                }
              },
              [_vm._v("Milestones")]
            ),
            _vm._v(" "),
            _vm._l(
              _vm.milestones_list.filter(function(m) {
                return m.player_name
              }),
              function(milestone) {
                return _c(
                  "span",
                  {
                    key: milestone.milestone.name,
                    staticClass: "milestone-award-inline paid",
                    attrs: { title: milestone.player_name }
                  },
                  [
                    _c(
                      "span",
                      { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                      [_vm._v(_vm._s(milestone.milestone.name))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ma-player-cube" }, [
                      _c("i", {
                        class:
                          "board-cube board-cube--" + milestone.player_color
                      })
                    ])
                  ]
                )
              }
            ),
            _vm._v(" "),
            _vm.isLearnerModeOn()
              ? _c(
                  "span",
                  _vm._l(_vm.getAvailableMilestoneSpots(), function(
                    spotPrice,
                    index
                  ) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "milestone-award-inline unpaid"
                      },
                      [
                        _c("div", { staticClass: "milestone-award-price" }, [
                          _vm._v(_vm._s(spotPrice))
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.shouldShowList(),
                expression: "shouldShowList()"
              }
            ]
          },
          _vm._l(_vm.milestones_list, function(milestone) {
            return _c(
              "div",
              {
                key: milestone.milestone.name,
                class: milestone.player_name
                  ? "ma-block pwned-item"
                  : "ma-block",
                attrs: { title: "press to show or hide the description" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggle(milestone)
                  }
                }
              },
              [
                milestone.player_name
                  ? _c("div", { staticClass: "ma-player" }, [
                      _c("i", {
                        class:
                          "board-cube board-cube--" + milestone.player_color,
                        attrs: { title: milestone.player_name }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [{ name: "i18n", rawName: "v-i18n" }],
                    staticClass: "ma-name--milestones",
                    class: _vm.getNameCss(milestone.milestone.name)
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(milestone.milestone.name) +
                        "\n                    "
                    ),
                    _vm.show_scores
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ma-scores player_home_block--milestones-and-awards-scores"
                          },
                          _vm._l(
                            milestone.scores.sort(function(s1, s2) {
                              return s2.playerScore - s1.playerScore
                            }),
                            function(score) {
                              return _c(
                                "p",
                                {
                                  key: score.playerColor,
                                  class:
                                    "ma-score player_bg_color_" +
                                    score.playerColor
                                },
                                [_vm._v(_vm._s(score.playerScore))]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shouldShow(milestone),
                        expression: "shouldShow(milestone)"
                      },
                      { name: "i18n", rawName: "v-i18n" }
                    ],
                    staticClass: "ma-description"
                  },
                  [_vm._v(_vm._s(milestone.milestone.description))]
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }