












import Vue from 'vue';
import {CardRequirements} from '../../cards/CardRequirements';

export default Vue.extend({
  name: 'CardRequirementsComponent',
  props: {
    requirements: {
      type: Object as () => CardRequirements,
      required: true,
    },
  },
  methods: {
    getClasses: function(): string {
      const classes: Array<string> = ['card-requirements'];
      if (this.requirements.hasMax()) {
        classes.push('card-requirements-max');
      }
      return classes.join(' ');
    },
  },
});

