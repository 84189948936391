var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "sidebar_cont sidebar " + _vm.getSideBarClass() }, [
    _c("div", { staticClass: "tm" }, [
      _c("div", { staticClass: "gen-text" }, [_vm._v("GEN")]),
      _vm._v(" "),
      _c("div", { staticClass: "gen-marker" }, [
        _vm._v(_vm._s(_vm.getGenMarker()))
      ])
    ]),
    _vm._v(" "),
    _vm.gameOptions.turmoilExtension
      ? _c("div", [
          _c(
            "div",
            {
              class:
                "party-name party-name-indicator party-name--" +
                _vm.rulingPartyToCss()
            },
            [_vm._v(" " + _vm._s(_vm.getRulingParty()))]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "global_params" },
      [
        _c("global-parameter-value", {
          attrs: {
            param: this.globalParameter.TEMPERATURE,
            value: this.temperature
          }
        }),
        _vm._v(" "),
        _c("global-parameter-value", {
          attrs: { param: this.globalParameter.OXYGEN, value: this.oxygen }
        }),
        _vm._v(" "),
        _c("global-parameter-value", {
          attrs: { param: this.globalParameter.OCEANS, value: this.oceans }
        }),
        _vm._v(" "),
        _vm.gameOptions.venusNextExtension
          ? _c("global-parameter-value", {
              attrs: { param: this.globalParameter.VENUS, value: this.venus }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.gameOptions.moonExpansion
          ? _c("MoonGlobalParameterValue", {
              attrs: { moonData: this.moonData }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar_item preferences_player" }, [
      _c("div", {
        class:
          _vm.getPlayerColorCubeClass() + " player_bg_color_" + _vm.player_color
      })
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("a", { attrs: { href: "#cards" } }, [
      _c(
        "div",
        { staticClass: "sidebar_item goto-cards sidebar_item_shortcut" },
        [
          _c(
            "i",
            { staticClass: "sidebar_icon sidebar_icon--cards" },
            [_vm._t("default")],
            2
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm.coloniesCount > 0
      ? _c("a", { attrs: { href: "#colonies" } }, [_vm._m(2)])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar_item sidebar_item--info" }, [
      _c("i", {
        staticClass: "sidebar_icon sidebar_icon--info",
        class: { "sidebar_item--is-active": _vm.ui.gamesetup_detail_open },
        attrs: { title: _vm.$t("game setup details") },
        on: {
          click: function($event) {
            _vm.ui.gamesetup_detail_open = !_vm.ui.gamesetup_detail_open
          }
        }
      }),
      _vm._v(" "),
      _vm.ui.gamesetup_detail_open
        ? _c(
            "div",
            { staticClass: "info_panel" },
            [
              _c("div", { staticClass: "info_panel-spacing" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "i18n", rawName: "v-i18n" }],
                  staticClass: "info-panel-title"
                },
                [_vm._v("Game Setup Details")]
              ),
              _vm._v(" "),
              _c("game-setup-detail", {
                attrs: {
                  gameOptions: _vm.gameOptions,
                  playerNumber: _vm.playerNumber,
                  lastSoloGeneration: _vm.lastSoloGeneration
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "info_panel_actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-lg btn-primary",
                    on: {
                      click: function($event) {
                        _vm.ui.gamesetup_detail_open = false
                      }
                    }
                  },
                  [_vm._v("Ok")]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("a", { attrs: { href: "/help", target: "_blank" } }, [
      _c("div", { staticClass: "sidebar_item sidebar_item--help" }, [
        _c("i", {
          staticClass: "sidebar_icon sidebar_icon--help",
          attrs: { title: _vm.$t("player aid") }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sidebar_item sidebar_item--settings" },
      [
        _c("i", {
          staticClass: "sidebar_icon sidebar_icon--settings",
          class: { "sidebar_item--is-active": _vm.ui.preferences_panel_open },
          on: {
            click: function($event) {
              _vm.ui.preferences_panel_open = !_vm.ui.preferences_panel_open
            }
          }
        }),
        _vm._v(" "),
        _c("preferences-dialog", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ui.preferences_panel_open,
              expression: "ui.preferences_panel_open"
            }
          ],
          on: {
            okButtonClicked: function($event) {
              _vm.ui.preferences_panel_open = false
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#board" } }, [
      _c("div", { staticClass: "sidebar_item sidebar_item_shortcut" }, [
        _c("i", { staticClass: "sidebar_icon sidebar_icon--board" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#actions" } }, [
      _c("div", { staticClass: "sidebar_item sidebar_item_shortcut" }, [
        _c("i", { staticClass: "sidebar_icon sidebar_icon--actions" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar_item sidebar_item_shortcut" }, [
      _c("i", { staticClass: "sidebar_icon sidebar_icon--colonies" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }