var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-container" },
    [
      _c("div", { staticClass: "help-tabs" }, [
        _c("input", {
          attrs: {
            type: "radio",
            name: "help-tab",
            id: "radio-symbols",
            checked: ""
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            attrs: { for: "radio-symbols" },
            on: {
              click: function($event) {
                return _vm.setTab("iconology")
              }
            }
          },
          [
            _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
              _vm._v("Game Iconology")
            ])
          ]
        ),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "radio",
            name: "help-tab",
            id: "radio-standard-projects"
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            attrs: { for: "radio-standard-projects" },
            on: {
              click: function($event) {
                return _vm.setTab("standard projects")
              }
            }
          },
          [
            _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
              _vm._v("Standard Projects")
            ])
          ]
        ),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "radio", name: "help-tab", id: "radio-phases" }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            attrs: { for: "radio-phases" },
            on: {
              click: function($event) {
                return _vm.setTab("phases")
              }
            }
          },
          [
            _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
              _vm._v("Game Phases")
            ])
          ]
        ),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "radio", name: "help-tab", id: "radio-hotkeys" }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            attrs: { for: "radio-hotkeys" },
            on: {
              click: function($event) {
                return _vm.setTab("hotkeys")
              }
            }
          },
          [
            _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
              _vm._v("Hot Keys")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.isOpen("iconology") ? _c("HelpIconology") : _vm._e(),
      _vm._v(" "),
      _vm.isOpen("standard projects") ? _c("HelpStandardProjects") : _vm._e(),
      _vm._v(" "),
      _vm.isOpen("phases") ? _c("HelpPhases") : _vm._e(),
      _vm._v(" "),
      _vm.isOpen("hotkeys")
        ? _c("div", [
            _c("div", { staticClass: "help-hotkeys" }, [
              _c("div", { staticClass: "keys" }, [
                _c(
                  "div",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Main Board")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Players Overview Table")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Cards in Hand")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Colonies")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "help-hotkeys-example" })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }