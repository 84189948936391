var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "start-screen" }, [
    _c(
      "div",
      {
        directives: [{ name: "i18n", rawName: "v-i18n" }],
        staticClass: "start-screen-links"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--new-game",
            attrs: { href: "/new-game" }
          },
          [_vm._v("New game")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--solo",
            attrs: { href: "/solo" }
          },
          [_vm._v("Solo challenge")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--cards-list",
            attrs: { href: "/cards", target: "_blank" }
          },
          [_vm._v("Cards list")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--board-game",
            attrs: {
              href:
                "https://boardgamegeek.com/boardgame/167791/terraforming-mars",
              target: "_blank"
            }
          },
          [_vm._v("Board game")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--about",
            attrs: {
              href: "https://github.com/bafolts/terraforming-mars#README",
              target: "_blank"
            }
          },
          [_vm._v("About us")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--changelog",
            attrs: {
              href:
                "https://github.com/bafolts/terraforming-mars/wiki/Changelog",
              target: "_blank"
            }
          },
          [_vm._v("Whats new?")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "i18n", rawName: "v-i18n" }],
            staticClass: "start-screen-link start-screen-link--chat",
            attrs: { href: "https://discord.gg/VR8TbrD", target: "_blank" }
          },
          [_vm._v("Join us on Discord")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "start-screen-header start-screen-link--languages" },
          [
            _c("language-switcher"),
            _vm._v(" "),
            _c("div", { staticClass: "start-screen-version-cont" }, [
              _c("div", { staticClass: "nowrap start-screen-date" }, [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("deployed")]
                ),
                _vm._v(": " + _vm._s(_vm.getAppDate()))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "nowrap start-screen-version" }, [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("version")]
                ),
                _vm._v(": " + _vm._s(_vm.getAppVersion()))
              ])
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "start-screen-header start-screen-link--title" },
      [
        _c("div", { staticClass: "start-screen-title-top" }, [
          _vm._v("TERRAFORMING")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "start-screen-title-bottom" }, [
          _vm._v("MARS")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }