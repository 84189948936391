var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "board-space-bonuses" },
    _vm._l(_vm.bonus, function(spaceBonus, idx) {
      return _c("i", { key: idx, class: _vm.getClass(idx + 1, spaceBonus) })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }