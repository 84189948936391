var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "games-overview" } }, [
    _c("h1", [_vm._v(_vm._s(_vm.constants.APP_NAME) + " — Games Overview")]),
    _vm._v(" "),
    _c("p", [_vm._v("The following games are available on this server:")]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.games, function(game) {
        return _c("li", { key: game.id }, [
          _c("a", { attrs: { href: "/game?id=" + game.id } }, [
            _vm._v(_vm._s(game.id))
          ]),
          _vm._v(
            "\n            with " +
              _vm._s(game.players.length) +
              " player(s) :\n            "
          ),
          _c(
            "span",
            { staticClass: "player_home_block nofloat" },
            [
              _vm._l(game.players, function(player) {
                return _c(
                  "span",
                  {
                    key: player.color,
                    staticClass: "player_name",
                    class: "player_bg_color_" + player.color
                  },
                  [
                    _c("a", { attrs: { href: "/player?id=" + player.id } }, [
                      _vm._v(_vm._s(player.name))
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _vm.isGameRunning(game.phase)
                ? _c("span", [_vm._v("is running")])
                : _c("span", [_vm._v("has ended")])
            ],
            2
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }