var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-tags" },
    _vm._l(_vm.tags, function(cardTag, index) {
      return _c("CardTag", {
        key: index,
        attrs: { index: index, type: cardTag }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }