















































































import Vue from 'vue';
import {PartyName} from '../turmoil/parties/PartyName';
import {$t} from '../directives/i18n';
import {TurmoilModel} from '../models/TurmoilModel';
import {BonusId} from '../turmoil/Bonus';
import {PolicyId} from '../turmoil/Policy';
import GlobalEvent from './GlobalEvent.vue';

const AGENDA_HTML: Map<BonusId | PolicyId, string> = new Map([
  ['mb01',
    `<div class="resource money party-resource">1</div> /
    <div class="resource-tag tag-building party-resource-tag"></div>`],
  ['mb02',
    `<div class="resource money party-resource">1</div> /
    <div class="tile empty-tile-small"></div>ON MARS`],
  ['sb01',
    `<div class="resource money party-resource">1</div> /
    <div class="resource-tag tag-science party-resource-tag"></div>`],
  ['sb02',
    `<div class="resource money party-resource">1</div> / 3
    <div class="resource card card-small"></div>`],
  ['ub01',
    `<div class="resource money party-resource">1</div> /
    <div class="resource-tag tag-venus party-resource-tag"></div>
    <div class="resource-tag tag-earth party-resource-tag"></div>
    <div class="resource-tag tag-jovian party-resource-tag"></div>`],
  ['ub02',
    `<div class="resource money party-resource">1</div> /
    <div class="resource-tag tag-space party-resource-tag"></div>`],
  ['kb01',
    `<div class="resource money party-resource">1</div> /
    <div class="production-box party-production-box">
      <div class="heat production"></div>
    </div>`],
  ['kb02',
    `<div class="resource heat party-resource"></div> /
    <div class="production-box party-production-box">
      <div class="heat production"></div>
    </div>`],
  ['rb01',
    `<div class="party-inferior-rating tile party-rating party-tile">&lt;</div> :
  <div class="rating tile party-rating party-tile"></div>`],
  ['rb02',
    `<div class="party-inferior-rating tile party-rating party-tile">&gt;</div> :
  <div class="rating tile party-rating party-tile red-outline "></div>`],
  ['gb01',
    `<div class="resource money party-resource">1</div> /
    <div class="resource-tag tag-plant party-resource-tag"></div>
    <div class="resource-tag tag-microbe party-resource-tag"></div>
    <div class="resource-tag tag-animal party-resource-tag"></div>`],
  ['gb02',
    `<div class="resource money party-resource">2</div> /
    <div class="tile greenery-tile greenery-tile-small"></div>`],
  ['mfp01',
    `<div class="policy-top-margin"><div class="tile empty-tile-small"></div> :
    <span class="steel resource"></span></div>`],
  ['mfp02',
    `<div class="policy-top-margin"><div class="resource-tag tag-building"></div> : <div class="money resource">2</div></div>`],
  ['mfp03',
    `<div class="policy-top-margin"><div class="resource steel"></div> : +<div class="resource money">1</div></div>`],
  ['mfp04',
    `<span class="money resource">4</span>
     <span class="red-arrow-3x"></span>
    <div class="resource card card-with-border policy-card-with-tag"><div class="card-icon tag-building"></div></div>`],
  ['sp01',
    `<span class="money resource">10</span>
    <span class="red-arrow"></span>
    <span class="card card-with-border resource party-resource"></span>
    <span class="card card-with-border resource party-resource"></span>
    <span class="card card-with-border resource party-resource"></span>`],
  ['sp02',
    `<span>
    <div class="tile oxygen-tile req-tile-small" style="margin: 10px -5px;"></div>
    <div class="tile ocean-tile req-tile-small"></div>
    <div class="tile temperature-tile req-tile-small"></div>
    : ± 2</span>`],
  ['sp03', `<span>
    <div class="tile oxygen-tile req-tile-small" style="margin: 10px -5px;"></div>
    <div class="tile ocean-tile req-tile-small"></div>
    <div class="tile temperature-tile req-tile-small"></div>
    : <div class="resource card card-with-border"></div></span>`],
  ['sp04', `<div class="scientists-requisite"><div class="resource-tag tag-science party-resource-tag"></div></div>`],
  ['up01',
    `<div class="policy-top-margin"><div class="resource titanium"></div> :
    + <div class="resource money">1</div></div>`],
  ['up02',
    `<div class="policy-top-margin">
    <span class="money resource">4</span>
    <span class="red-arrow-3x"></span>2<span class="titanium resource"></span> / 2<span class="floater resource"></span>
    </div>`],
  ['up03',
    `<span class="money resource">4</span>
    <span class="red-arrow-3x"></span>
    <div class="resource card card-with-border policy-card-with-tag"><div class="card-icon tag-space"></div></div>`],
  ['up04', `<div class="policy-top-margin"><div class="resource-tag tag-space"></div> : <div class="money resource">-2</div></div>`],
  ['kp01',
    `<span class="money resource">10</span>
    <span class="red-arrow-infinity"></span>
    <div class="production-box production-box-size2">
      <div class="energy production"></div>
      <div class="heat production"></div>
    </div>`],
  ['kp02', `<div class="tile temperature-tile req-tile-small" style="margin-right:5px;"></div> : <span class="money resource">3</span>`],
  ['kp03',
    `6 <span class="heat resource"></span>
    <span class="red-arrow-infinity"></span>
    <div class="tile temperature-tile"></div>`],
  ['kp04',
    `<div class="policy-top-margin"><div class="tile empty-tile-small"></div> :
    <span class="heat resource"></span><span class="heat resource"></span></div>`],
  ['rp01',
    `<div class="policy-top-margin">
    <div class="rating tile"></div> :
    <div class="resource money">-3</div>
    </div>`],
  ['rp02', `<div class="policy-top-margin"><div class="tile empty-tile-small"></div> : <span class="money resource">-3</span></div>`],
  ['rp03',
    `<span class="money resource">4</span>
    <span class="red-arrow-3x"></span>
    <div class="tile oxygen-tile req-tile-small red-outline" style="margin: 10px -5px;"></div> /
    <div class="tile ocean-tile req-tile-small red-outline"></div> /
    <div class="tile temperature-tile req-tile-small red-outline"></div>`],
  ['rp04',
    `<div class="tile oxygen-tile req-tile-small" style="margin: 10px -5px;"></div>
    <div class="tile ocean-tile req-tile-small"></div>
    <div class="tile temperature-tile req-tile-small"></div>
    : <div class="production-box production-box-size2" style="margin-left:5px;">
      <div class="production-prefix minus"></div><div class="money production">1</div>
    </div>`],
  ['gp01', `<div class="tile greenery-tile"></div> : <div class="resource money">4</div>`],
  ['gp02',
    `<div class="policy-top-margin"><div class="tile empty-tile-small"></div> :
    <span class="plant resource"></span></div>`],
  ['gp03',
    `<div class="policy-top-margin">
    <div class="resource-tag tag-plant party-resource-tag"></div>
    <div class="resource-tag tag-microbe party-resource-tag"></div>
    <div class="resource-tag tag-animal party-resource-tag"></div> : <div class="resource money">2</div>
    </div>`],
  ['gp04',
    `<div class="policy-top-margin">
    <span class="money resource">5</span>
    <span class="red-arrow-3x"></span>3<span class="plant resource"></span> / 2<span class="microbe resource"></span>
    </div>`],
]);

export default Vue.extend({
  name: 'turmoil',
  props: {
    turmoil: {
      type: Object as () => TurmoilModel,
    },
  },
  methods: {
    partyNameToCss: function(party: PartyName | undefined): string {
      if (party === undefined) {
        console.warn('no party provided');
        return '';
      }
      return party.toLowerCase().split(' ').join('_');
    },
    getBonus: function(party: PartyName | undefined): string {
      const politicalAgendas = this.turmoil.politicalAgendas;
      let bonusId: BonusId | undefined = undefined;
      if (politicalAgendas !== undefined) {
        switch (party) {
        case PartyName.MARS:
          bonusId = politicalAgendas.marsFirst.bonusId;
          break;
        case PartyName.SCIENTISTS:
          bonusId = politicalAgendas.scientists.bonusId;
          break;
        case PartyName.UNITY:
          bonusId = politicalAgendas.unity.bonusId;
          break;
        case PartyName.KELVINISTS:
          bonusId = politicalAgendas.kelvinists.bonusId;
          break;
        case PartyName.REDS:
          bonusId = politicalAgendas.reds.bonusId;
          break;
        case PartyName.GREENS:
          bonusId = politicalAgendas.greens.bonusId;
          break;
        }
      }
      if (bonusId !== undefined) {
        const bonus = AGENDA_HTML.get(bonusId);
        return bonus || `No ruling Bonus`;
      }
      return `No ruling Bonus`;
    },
    getPolicy: function(partyName: PartyName | undefined) {
      const politicalAgendas = this.turmoil.politicalAgendas;
      let policyId: PolicyId | undefined = undefined;
      if (politicalAgendas !== undefined) {
        switch (partyName) {
        case PartyName.MARS:
          policyId = politicalAgendas.marsFirst.policyId;
          break;
        case PartyName.SCIENTISTS:
          policyId = politicalAgendas.scientists.policyId;
          break;
        case PartyName.UNITY:
          policyId = politicalAgendas.unity.policyId;
          break;
        case PartyName.KELVINISTS:
          policyId = politicalAgendas.kelvinists.policyId;
          break;
        case PartyName.REDS:
          policyId = politicalAgendas.reds.policyId;
          break;
        case PartyName.GREENS:
          policyId = politicalAgendas.greens.policyId;
          break;
        }
      }

      if (policyId !== undefined) {
        const policy = AGENDA_HTML.get(policyId);
        return policy || `No ruling Policy`;
      }
      return '<p>' + $t('No ruling Policy') + '</p>';
    },
    toggleMe: function() {
      const currentState: boolean = this.isVisible();
      (this.$root as any).setVisibilityState('turmoil_parties', ! currentState);
    },
    isVisible: function() {
      return (this.$root as any).getVisibilityState('turmoil_parties');
    },
  },
  components: {
    'global-event': GlobalEvent,
  },
});

