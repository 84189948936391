var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-extra-content-container" }, [
    _vm.lifeFound(_vm.card)
      ? _c("div", { staticClass: "little-green-men" })
      : _vm._e(),
    _vm._v(" "),
    _vm.isMiningTileOnMetal(_vm.card, "steel")
      ? _c("div", { staticClass: "mined-metal mined-steel" })
      : _vm._e(),
    _vm._v(" "),
    _vm.isMiningTileOnMetal(_vm.card, "titanium")
      ? _c("div", { staticClass: "mined-metal mined-titanium" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }