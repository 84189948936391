var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wf-component wf-component--select-option" },
    [
      _vm.showtitle === true
        ? _c("div", { staticClass: "wf-component-title" }, [
            _vm._v(_vm._s(_vm.$t(_vm.playerinput.title)))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showsave === true
        ? _c("Button", {
            attrs: {
              size: "big",
              onClick: _vm.saveData,
              title: _vm.$t(_vm.playerinput.buttonLabel)
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }