var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.componentKey, class: _vm.formatCssClass() },
    [
      _c("PlayerInfo", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpanded(),
            expression: "isExpanded()"
          }
        ],
        attrs: {
          player: _vm.player,
          activePlayer: _vm.player,
          actionLabel: "",
          playerIndex: 0,
          hideZeroTags: true
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "top-bar-collapser",
          on: {
            click: function($event) {
              return _vm.toggleBar()
            }
          }
        },
        [_c("img", { attrs: { src: "/assets/arrows_left.png" } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }