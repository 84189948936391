var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language-switcher" },
    _vm._l(_vm.languages, function(lang) {
      return _c("div", {
        key: lang.id,
        class: "language-icon language-icon--" + lang.id,
        attrs: { title: lang.title },
        on: {
          click: function($event) {
            return _vm.switchLanguageTo(lang.id)
          }
        }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }