





















import Vue from 'vue';
import Card from './../card/Card.vue';
import {CardName} from './../../CardName';

export default Vue.extend({
  name: 'HelpStandardProjects',
  components: {
    Card,
  },
  methods: {
    getBasicStandardProjects: () => [
      CardName.SELL_PATENTS_STANDARD_PROJECT,
      CardName.POWER_PLANT_STANDARD_PROJECT,
      CardName.ASTEROID_STANDARD_PROJECT,
      CardName.AQUIFER_STANDARD_PROJECT,
      CardName.GREENERY_STANDARD_PROJECT,
      CardName.CITY_STANDARD_PROJECT,
    ],
    getExpansionStandardProjects: () => [
      CardName.AIR_SCRAPPING_STANDARD_PROJECT,
      CardName.BUILD_COLONY_STANDARD_PROJECT,
      CardName.BUFFER_GAS_STANDARD_PROJECT,
    ],
    getFanMadeStandardProjects: () => [
      CardName.MOON_COLONY_STANDARD_PROJECT,
      CardName.MOON_MINE_STANDARD_PROJECT,
      CardName.MOON_ROAD_STANDARD_PROJECT,
    ],
  },
});
