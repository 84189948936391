var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "help-phases-container" }, [
    _c("ul", [
      _c("li", [
        _c("input", { attrs: { type: "checkbox", id: "initial-draft" } }),
        _vm._v(" "),
        _c("label", { staticClass: "first", attrs: { for: "initial-draft" } }, [
          _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Initial Drafting (optional variant)")
          ])
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Draw 3 corporation cards")
          ]),
          _vm._v(" "),
          _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Draw 4 prelude cards "),
            _c("div", { staticClass: "expansion-icon expansion-icon-prelude" })
          ]),
          _vm._v(" "),
          _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v(
              "Draw 5 project cards. Draft. Pick one card and pass to the player above in turn order. Repeat until you have 5 cards."
            )
          ]),
          _vm._v(" "),
          _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v(
              "Draw 5 more project cards. Draft and pass to the player below in turn order instead."
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
              _vm._v(
                "Draft the prelude cards. Pick one card and pass to the player above in turn order. Repeat until you have 4 preludes."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "expansion-icon expansion-icon-prelude" })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("input", { attrs: { type: "checkbox", id: "generation" } }),
        _vm._v(" "),
        _c("label", { attrs: { for: "generation" } }, [
          _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Each generation")
          ])
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("input", {
              attrs: { type: "checkbox", id: "initial-research" }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [{ name: "i18n", rawName: "v-i18n" }],
                attrs: { for: "initial-research" }
              },
              [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Initial Research Phase (Generation 1 only)")]
                )
              ]
            ),
            _vm._v(" "),
            _c("ul", [
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v(
                  "Select which corporation, 2 preludes and project cards to keep."
                )
              ]),
              _vm._v(" "),
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v(
                  "In turn order, play the corporation and pay 3 M€ for each of the project cards bought."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("In turn order, play both preludes.")]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "expansion-icon expansion-icon-prelude"
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("input", { attrs: { type: "checkbox", id: "research-phase" } }),
            _vm._v(" "),
            _c("label", { attrs: { for: "research-phase" } }, [
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Research Phase (Generation 2+)")]
              )
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Draw four project cards")
              ]),
              _vm._v(" "),
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v(
                  "(Optional variant: draft the cards. Pick one pass to the player below in even generation and above in odd generation. Repeat until you have four cards.)"
                )
              ]),
              _vm._v(" "),
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Decide how many cards to buy")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("input", { attrs: { type: "checkbox", id: "action-phase" } }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "last", attrs: { for: "action-phase" } },
              [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Action phase")]
                ),
                _vm._v("\n            ("),
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("Turmoil policy in effect")]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "expansion-icon expansion-icon-turmoil"
                }),
                _vm._v("\n            )\n          ")
              ]
            ),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [
                    _vm._v(
                      "Take 1 or 2 actions (mandatory 2 actions if playing with fast mode):"
                    )
                  ]
                ),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("play card")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("use active card action")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("standard project")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("convert plants to greenery")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("convert heat to temperature")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("claim milestone")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("fund award")]
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "span",
                      { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                      [_vm._v("trade with 9 M€, 3 Ti, or 3 energy")]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "expansion-icon expansion-icon-colony"
                    })
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "span",
                      { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                      [
                        _vm._v(
                          "send delegate: free from lobby, 5 M€ from reserve"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "expansion-icon expansion-icon-turmoil"
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Pass for the generation")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("input", {
              attrs: { type: "checkbox", id: "production-phase" }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "production-phase" } }, [
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Production Phase")]
              )
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Energy becomes heat")
              ]),
              _vm._v(" "),
              _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
                _vm._v("Produce resources")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("input", { attrs: { type: "checkbox", id: "solar-phase" } }),
            _vm._v(" "),
            _c("label", { attrs: { for: "solar-phase" } }, [
              _c(
                "span",
                { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                [_vm._v("Solar Phase")]
              )
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("i. Game end check")]
                ),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [
                      _vm._v(
                        "If temperature, oxygen, and oceans are maxed, skip the rest of solar phase to the end game phase. "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [
                      _vm._v(
                        "(If playing solo, the game ends after 14 generations.)"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("Solo mode adjustments for expansions:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("• Prelude: The game ends 2 generations earlier.")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [
                      _vm._v(
                        "• Venus Next: The Venus track must be completed for solo mode victory."
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("• The Moon (63TR condition): No changes.")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [
                      _vm._v(
                        "• The Moon (Terraforming condition): The game ends 2 generations later. The Moon must tracks must be completed for solo mode victory."
                      )
                    ]
                  )
                ])
              ]),
              _c("li", [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [
                    _vm._v(
                      "ii. World Government Terraforming (start player chooses)"
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "expansion-icon expansion-icon-venus"
                })
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("iii. Colony production")]
                ),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("Trade fleets return")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [
                      _vm._v(
                        "Each colony tile track is increased 1 step if possible"
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "span",
                  { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                  [_vm._v("iv. Turmoil")]
                ),
                _c("div", {
                  staticClass: "expansion-icon expansion-icon-turmoil"
                }),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [_vm._v("1. TR Revision: All players lose 1 TR")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                    [
                      _vm._v(
                        "2. Global Event: Perform the current Global Event"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "span",
                      { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                      [_vm._v("3. New Government")]
                    ),
                    _vm._v(" "),
                    _c("ul", [
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [_vm._v("Change policy tile")]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "Perform the ruling bonus of the dominant party"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "The dominant party leader becomes chairman, earning 1 TR."
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "Move the old chairman and all other delegates from the dominant party to the reserves."
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "Shift the dominance marker. In case of tie, the party closest to the left of the previous dominant party becomes dominant."
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [_vm._v("Restore lobby")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "span",
                      { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                      [_vm._v("4. Changing Times")]
                    ),
                    _vm._v(" "),
                    _c("ul", [
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "Move the coming (middle) Global Event to current position (right). Add its lower neutral delegate."
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "Move the distant (left) Global Event to coming position (middle)"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { directives: [{ name: "i18n", rawName: "v-i18n" }] },
                        [
                          _vm._v(
                            "Reveal a new Global Event in the distant (left) position. Add its top neutral delegate"
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("input", { attrs: { type: "checkbox", id: "end-game" } }),
        _vm._v(" "),
        _c("label", { attrs: { for: "end-game" } }, [
          _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("End Game")
          ])
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("In turn order, convert plants to greeneries.")
          ]),
          _vm._v(" "),
          _c("li", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
            _vm._v("Assign awards")
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", { directives: [{ name: "i18n", rawName: "v-i18n" }] }, [
              _vm._v(
                " Score: TR + tiles + card + milestones + awards + (1 VP per chairman and party leader"
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "expansion-icon expansion-icon-turmoil" }),
            _vm._v(")\n        ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }