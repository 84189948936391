var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.getComponentName(_vm.playerinput.inputType), {
    tag: "component",
    attrs: {
      players: _vm.players,
      player: _vm.player,
      playerinput: _vm.playerinput,
      onsave: _vm.onsave,
      showsave: _vm.showsave,
      showtitle: _vm.showtitle
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }