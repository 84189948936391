var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "player-status" }, [
    _c("div", { staticClass: "player-status-bottom" }, [
      _c("div", { class: _vm.getLabelAndTimerClasses() }, [
        _c("div", { class: _vm.getActionStatusClasses() }, [
          _vm._v(_vm._s(_vm.actionLabel))
        ]),
        _vm._v(" "),
        _vm.player.game.gameOptions.showTimers
          ? _c(
              "div",
              { staticClass: "player-status-timer" },
              [_c("player-timer", { attrs: { timer: _vm.player.timer } })],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }