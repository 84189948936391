var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-resources-counter" }, [
    _c("span", { staticClass: "card-resources-counter-label" }, [
      _vm._v("res:")
    ]),
    _c("span", { staticClass: "card-resources-counter-number" }, [
      _vm._v(" " + _vm._s(_vm.amount))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }