var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cardbox" },
    _vm._l(_vm.cards, function(card, index) {
      return _c(
        "div",
        {
          key: card.name,
          class: { "cards-stack": index > 0, "cards-stack-first": index === 0 }
        },
        [_c("Card", { attrs: { card: card } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }