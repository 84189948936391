




















import Vue from 'vue';

import {Phase} from '../Phase';

import * as constants from '../constants';

export default Vue.extend({
  name: 'games-overview',
  data: function() {
    return {
      constants,
      serverId: '',
      games: {},
    };
  },
  mounted: function() {
    this.serverId = (new URL(location.href)).searchParams.get('serverId') || '';
    this.getGames();
  },
  methods: {
    getGames: function() {
      const vueApp = this;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '/api/games?serverId='+this.serverId);
      xhr.onerror = function() {
        alert('Error getting games data');
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          const result = xhr.response;
          if (result instanceof Array) {
            result.forEach(function(gameId) {
              (vueApp as any).getGame(gameId.id);
            });
          } else {
            alert('Unexpected response fetching games from API');
          }
        } else {
          alert('Unexpected response fetching games from API');
        }
      };
      xhr.responseType = 'json';
      xhr.send();
    },
    getGame: function(gameId: string) {
      const vueApp = this;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '/api/game?id='+gameId);
      xhr.onerror = function() {
        alert('Error getting game data');
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          const result = xhr.response;
          if (result instanceof Object) {
            Vue.set((vueApp as any).games, gameId, result);
          } else {
            alert('Unexpected response fetching game \'+gameId+\' from API');
          }
        } else {
          alert('Unexpected response fetching game \'+gameId+\' from API');
        }
      };
      xhr.responseType = 'json';
      xhr.send();
    },
    isGameRunning: function(gamePhase: string): boolean {
      return (gamePhase === Phase.END) ? false : true;
    },
  },
});

