












import Vue from 'vue';
import CardRenderItemComponent from './CardRenderItemComponent.vue';
import CardRenderEffectBoxComponent from './CardRenderEffectBoxComponent.vue';
import CardRenderSymbolComponent from './CardRenderSymbolComponent.vue';
import {CardRenderEffect} from '../../cards/render/CardRenderer';
import {CardRenderItem} from '../../cards/render/CardRenderItem';
import {CardRenderSymbol} from '../../cards/render/CardRenderSymbol';

export default Vue.extend({
  name: 'CardRenderCorpBoxComponent',
  props: {
    rows: {
      type: Array as () => Array<CardRenderEffect | CardRenderItem | CardRenderSymbol>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  components: {
    CardRenderSymbolComponent,
    CardRenderItemComponent,
    CardRenderEffectBoxComponent,
  },
  methods: {
    getClasses: function(): string {
      const classes: Array<string> = ['card-corporation-box'];
      return classes.join(' ');
    },
    getComponentType: function(rowItem: CardRenderEffect | CardRenderItem | CardRenderSymbol): string {
      if (rowItem instanceof CardRenderSymbol) {
        return 'symbol';
      } else if (rowItem instanceof CardRenderEffect) {
        return 'effect';
      } else if (rowItem instanceof CardRenderItem) {
        return 'item';
      }
      return '';
    },
  },
});

