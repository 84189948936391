













import Vue from 'vue';
import CardRenderItemComponent from './CardRenderItemComponent.vue';
import CardRenderSymbolComponent from './CardRenderSymbolComponent.vue';
import {CardRenderSymbol} from '../../cards/render/CardRenderSymbol';
import {CardRenderItem} from '../../cards/render/CardRenderItem';

export default Vue.extend({
  name: 'CardProductionBoxComponent',
  props: {
    rows: {
      type: Array as () => Array<Array<CardRenderItem>>,
      required: true,
    },
  },
  components: {
    CardRenderItemComponent,
    CardRenderSymbolComponent,
  },
  methods: {
    getClasses: function(): string {
      const classes: Array<string> = ['card-production-box'];
      return classes.join(' ');
    },
    getComponentType: function(rowItem: CardRenderSymbol | CardRenderItem): string {
      if (rowItem instanceof CardRenderSymbol) {
        return 'symbol';
      } else if (rowItem instanceof CardRenderItem) {
        return 'item';
      }
      return '';
    },
  },
});

