var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isObject(_vm.victoryPoints) && !this.victoryPoints.targetOneOrMore
    ? _c(
        "div",
        { class: _vm.getClasses() },
        [
          _c("div", [_vm._v(_vm._s(this.victoryPoints.getPointsHtml()))]),
          this.victoryPoints.item !== undefined
            ? _c("CardRenderItemComponent", {
                attrs: { item: this.victoryPoints.item }
              })
            : _vm._e()
        ],
        1
      )
    : _vm.isObject(_vm.victoryPoints) && this.victoryPoints.targetOneOrMore
    ? _c("div", { class: _vm.getClasses() }, [
        _c(
          "div",
          { staticClass: "card-points-item-first" },
          [
            this.victoryPoints.item !== undefined
              ? _c("CardRenderItemComponent", {
                  attrs: { item: this.victoryPoints.item }
                })
              : _vm._e(),
            _vm._v("*:3")
          ],
          1
        )
      ])
    : _c("div", { class: _vm.getClasses() }, [
        _vm._v(_vm._s(this.victoryPoints))
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }